@import '../../css/guideline.scss';

body {
  .content-post {
    .html-container {
      strong,
      b {
        font-weight: 700;
      }
    }

    a {
      color: #10008c !important;
    }

    .vk_widget {
      margin-top: 20px;
    }

    &.lite {
      .wrapper {
        margin: 0;
        padding-top: 40px;
        padding-bottom: 160px;

        .html-container {
          padding: 0;
        }
      }
    }

    &.fullpage {
      .wrapper {
        margin-top: 0;
        padding: 0;

        @include media(
          (
            margin-top: (
              // all: 0px,
                all: -40px,
            ),
            margin-bottom: (
              all: 120px,
              mobile: 80px,
            ),
          )
        );

        .share-container {
          padding: 40px 40px 0;
        }

        .html-container {
          width: 100%;
          min-width: 100%;
          margin-left: 0;
          padding-top: 0;
        }
      }
    }

    .wrapper {
      display: flex;
      justify-content: space-between;

      .sticky-inner-wrapper {
        @include media(
          (
            position: (
              tablet: relative !important,
            ),
            transform: (
              tablet: none !important,
            )
          )
        )
      }

      @include media(
        (
          flex-direction: (
            all: row,
            tablet: column,
          ),
          margin-top: (
            all: 40px,
            tablet: 60px,
            mobile: 40px,
          ),
          margin-bottom: (
            all: 120px,
            mobile: 80px,
          ),
        )
      );

      .line-container {
        padding: 0;
        .wrapper {
          margin: 40px 0;
          padding: 0;
        }
      }

      & > div {
        width: 100%;
      }

      .title-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        @include media(
          (
            transform: (
              all: translateY(20px),
              tablet: none,
            ),
          )
        );

        &.sticky {
          max-width: calc((100% - 80px) * 0.33333 - 120px);
        }

        .title {
          width: 100%;
          padding-top: 20px;

          border-color: $black;
          border-top: $lightBorder;
        }

        .heading {
          margin-bottom: 32px;
          @include media(
          (
            font-size: (
              tablet: 24px,
            ),
          )
        );
        }

        .share-block {
          display: flex;
          justify-content: space-between;

          margin-top: 20px;
          padding-top: 20px;

          border-color: $black;
          border-top: $lightBorder;
        }
      }

      .html-container {
        letter-spacing: -2px;

        line-height: 1.25;

        @include media(
          (
            padding-top: (
              all: 20px,
              tablet: 60px,
              mobile: 40px,
            ),
            width: (
              all: 66.67%,
              tablet: 100%,
            ),
            margin-left: (
              all: 120px,
              tablet: 0,
            ),
            min-width: (
              all: 66.67%,
              tablet: 100%,
            ),
          )
        );
      }
    }
  }
}
