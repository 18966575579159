body {
  .not-found {
    opacity: 0.5;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    padding: 80px 0;
  }
}
