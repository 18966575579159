@import '../../css/guideline.scss';

body {
  .tags-list {
    background: transparent;

    @include media(
      (
        padding: (
          all: 40px,
          mobile: 40px 20px
        )
      )
    );

    .button {
      margin-top: 20px;
      background: $white;
      color: $black;
      box-sizing: border-box;
      width: 240px;
      max-width: 100%;
      height: 70px;

      transition: $transition;

      &:hover {
        color: $white;
        background: $black;
      }
    }

    .tags-list-title {
      margin-bottom: 20px;
    }

    .tags-list-content {
      display: flex;
      flex-wrap: wrap;

      &.additional {
        display: none;
        overflow: hidden;
        height: 0;

        &.show {
          display: block;
          height: auto;
        }
      }

      .tag {
        height: 60px;
        color: $black;
        display: flex;
        cursor: pointer;
        padding: 0 20px;
        background: $white;
        align-items: center;
        margin: 20px 20px 0 0;
        box-sizing: border-box;
        transition: $transition;

        span {
          padding-top: 4px;
        }

        &:hover {
          color: $white;
          background: rgba(0, 0, 0, 0.6);
        }

        &.active {
          color: $white;
          background: $black;
        }
      }
    }
  }
  .bannerx {
    width: 100%;
  }
  @media screen and (max-width: 1025px) {
    .bannerx {
      display: none;
    }

    .tags-list {
      display: none;
    }

    .news-line {
      display: none;
    }
  }
}
