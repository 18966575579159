@import '../../css/guideline.scss';

.networks {
  .society-container {
    .slick-slide {
      @include media(
        (
          min-width: (
            all: calc(100vw / 4),
            tablet: calc(100vw / 2)
          ),
          max-width: (
            all: calc(100vw / 4),
            tablet: calc(100vw / 2)
          ),
          width: (
            all: calc(100vw / 4),
            tablet: calc(100vw / 2)
          )
        )
      );
    }
  }
}