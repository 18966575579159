@import '../../css/guideline.scss';

body {
  footer {
    width: 100%;
    color: $white;
    background: $black;
    box-sizing: border-box;

    &.error-page {
      border-top: $lightBorder;
    }

    @include media(
      (
        padding: (
          all: 100px 40px,
          mobile: 60px 20px
        )
      )
    );

    .line {
      width: 100%;
      height: 2px;
      background: $veryLightBlack;
    }

    display: flex;
    flex-direction: column;

    .information {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;

      @include media(
        (
          flex-direction: (
            all: row,
            tablet: column
          ),
          align-items: (
            all: center,
            tablet: flex-start
          )
        )
      );

      & > div {
        display: flex;

        @include media(
          (
            flex-direction: (
              all: row,
              mobile: column
            ),
            align-items: (
              all: center,
              mobile: flex-start
            ),
            justify-content: (
              all: center,
              mobile: flex-start
            )
          )
        );

        &.download-buttons {
          @include media(
            (
              flex-direction: (
                all: row,
                mobile: row
              )
            )
          );
        }
      }

      .logotype {
        cursor: pointer;

        .svg-image {
          svg {
            transition: $transition;
            fill: #959ca4;
          }
        }

        .copyright {
          transition: $transition;
          white-space: pre;
          margin-top: 5px;
          color: $darkGray;
        }

        &:hover {
          .svg-image {
            svg {
              fill: $white;
            }
          }

          .copyright {
            color: $white;
          }
        }
      }

      .description {
        max-width: 800px;
        margin-left: 40px;

        .is-static-navigation {
          margin-top: 0;

          @include media(
            (
              margin-bottom: (
                all: 10px,
                mobile: 30px
              )
            )
          );
        }

        @include media(
          (
            margin-left: (
              all: 40px,
              mobile: 0
            ),
            margin-top: (
              all: 0,
              mobile: 30px
            ),
            max-width: (
              all: 800px,
              mobile: initial
            )
          )
        );
      }
    }
  }
  .stations {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .station {
      opacity: 0.8;
      transition: 0.2s;
      text-align: center;
      svg {
        max-width: 100%;
      }
      @include media(
        (
          width: (
            all: 10%,
            tablet: 40%
          )
        )
      )
    }
    .station:hover {
      opacity: 1;
    }
  }
}
