@import '../../css/guideline.scss';

body {
  .loader {
    width: 40px;
    height: 40px;

    &.gray {
      svg {
        stroke: $black;
      }
    }

    &.live-loader {
      position: absolute;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      height: 100%;

      & > div {
        width: 10%;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
