@import '../../css/guideline.scss';

body {
  .social-list {
    width: 100%;
    display: flex;
    flex-direction: column;

    .socials {
      display: flex;

      @include media(
        (
          flex-wrap: (
            all: no-wrap,
            tablet: wrap
          )
        )
      );

      .social {
        display: flex;
        height: 240px;
        color: $white;
        background: $red;
        position: relative;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include media(
          (
            width: (
              all: 100%,
              tablet: 25%,
              mobile: 50%
            )
          )
        );

        &[data-network='vk'] {
          background: $vk;
        }

        &[data-network='fb'] {
          background: $fb;
        }

        &[data-network='yt'] {
          background: $youtube;
        }

        &[data-network='in'] {
          background: $instagram;
        }

        &[data-network='site'] {
          background: $website;

          .svg-image {
            svg {
              width: 100%;
              height: 100%;

              path {
                fill: transparent;
                stroke: $white;
              }
            }
          }
        }

        &:hover {
          .preset {
            opacity: 0.8;
          }
        }

        .preset {
          transition: $transition;
          position: absolute;
          left: 0;
          top: 0;
          background: $black;
          opacity: 0;
          width: 100%;
          height: 100%;
        }

        .social-name {
          position: relative;
          z-index: 5;
        }

        .svg-image {
          z-index: 5;
          width: 60px;
          height: 60px;
          position: relative;
          margin-bottom: 20px;

          & > div {
            width: 100%;
            height: 100%;
          }

          svg {
            width: 100%;
            height: 100%;

            path {
              fill: $white;
              transition: $transition;
            }
          }
        }
      }
    }
  }
}
