@import '../../css/guideline.scss';

$timelineHeight: 100px;
$timelinePadding: 15px;
$timelineHeightMobile: 40px;
$maxHour: 24;

body {
  .timetable-container {
    padding-bottom: 40px;

    .schedule-loader {
      width: 60px;
      height: 60px;
      margin: 30px auto 0;
      padding-bottom: 40px;
    }

    &.wrapper {
      &:not(.today) {
        padding-bottom: 40px;
      }
    }

    &.gray {
      background: $gray;
    }

    .timetable-title {
      padding-top: 60px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .timetable-today {
      margin-top: 15px;
    }

    .empty-list {
      opacity: 0.5;
      display: flex;
      padding: 30px 0;
    }

    .timetable {
      margin-top: 60px;
      background: $gray;
      position: relative;

      .timeline {
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid $black;

        @include media(
          (
            min-height: (
              mobile: $timelineHeightMobile
            ),
            height: (
              all: $timelineHeight,
              mobile: auto
            ),
            padding: (
              all: $timelinePadding 0 0 0,
              mobile: $timelinePadding 0
            )
          )
        );

        &:first-child {
          border-top: 1px solid $black;
        }
      }

      .event {
        @include media(
          (
            margin-top: (
              mobile: 20px
            )
          )
        );

        .event-name {
          color: $black;
        }
      }

      .events {
        position: absolute;
        display: flex;
        width: 100%;
        left: 15%;
        top: 0;

        .event-column {
          width: 50%;
        }

        .right {
          left: 50%;
        }

        .event {
          display: flex;
          position: absolute;

          .event-content {
            display: flex;
            padding: 10px 0;
          }

          .event-info {
            padding-left: 20px;
          }

          .event-timeline {
            width: 10px;
            background: $black;
          }
        }

        $top: 100px;
        $height: 100px;

        [data-mid-start='true'] {
          padding: 40px 0 10px 0 !important;
        }
        [data-mid-end='true'] {
          padding: 10px 0 40px 0 !important;
        }

        [data-mid-start='true'][data-mid-end='true'] {
          padding: 40px 0 !important;
        }

        [data-margin='0'] {
          top: 0;
        }

        @for $i from 1 through $maxHour {
          [data-margin='#{$i}'] {
            top: $top * $i;
          }
        }

        [data-duration='0'] {
          height: $height;
        }

        @for $i from 1 through $maxHour {
          [data-duration='#{$i}'] {
            height: $height * $i;
          }
        }
      }
    }
  }
}
