@import './guideline.scss';

body {
  #app {
    overflow-x: hidden;

    background: $white;

    .app-container {
      position: relative;
      z-index: $zIndexApp;
    }

    .broadcast-page {
      .broadcast-wrapper {
        background-color: white;

        @include media(
          (
            padding: (
              all: 0 15%,
              tablet: 0
            )
          )
        );
      }
    }
  }

  section {
    overflow-x: hidden;
  }

  .second-level-page {
    &.no-header-post {
      margin-top: calc(#{$playerHeight} + 40px);
    }
  }

  .wrapper {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;

    @include media(
      (
        padding: (
          all: 0 40px,
          mobile: 0 20px
        )
      )
    );

    &.no-padding {
      padding: 0;
    }

    &.rtl {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }

  .buttons-block {
    .button {
      padding: 20px 60px;
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 26px 56px;

    cursor: pointer;
    transition: $transition;
    text-align: center;

    border: 2px solid transparent;

    &[data-background='white'] {
      color: $black;
      background: $white;

      &:hover {
        color: #454d9c;
        background: rgb(226, 226, 226);
      }
    }
  }
}
