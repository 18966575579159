@import '../../css/guideline.scss';

.svg-image {
  display: flex;
  position: relative;

  &_clickable {
    cursor: pointer;
  }

  svg {
    width: 100%;
    transition: $transition;
  }
}
