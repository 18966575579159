.slick-slider {
  a,
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  
  &:not(.vertical) {
    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;
      }
    }
  }
}
