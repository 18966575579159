@import '../../../../css/guideline.scss';
// @import './reset.scss';
// @import './media.scss';
@import '~slick-carousel/slick/slick.css';
@import '~nouislider/distribute/nouislider.min.css';

$red: $red;
$white: white;

$backgroundColor: #f1f1f1;

$logoHeight: 72px;
$panelHeight: 96px;
$songPreviewHeight: 80px;
$broadcastCoverHeight: 152px;
$stationItemImageHeight: 64px;

$svgIconSize: 48px;
$svgButtonFill: $red;

$zIndexBanner: 7550;
$zIndexPlayer: 7500;
$zIndexPlayerPanel: 10;
$zIndexPlayerOverlay: 5;

$textIndent: -9999px;
$transition: all 500ms;

$mediaSize: 768px;

body {
  background: black;

  &.mp_opened {
    overflow: hidden;

    height: 100%;
  }
}

#mp {
  position: fixed;
  z-index: $zIndexPlayer;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;

  width: 100%;
  height: $panelHeight;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;

  * {
    box-sizing: border-box;
  }
}

.mp-player,
#mp {
  svg {
    width: 100%;
    height: 100%;

    .mp-play,
    .mp-hide,
    .mp-pause,
    .mp-show {
      transition: opacity 500ms;

      fill: $white;
      stroke: $white;
    }

    &.mp-icon {
      circle {
        fill: $svgButtonFill;
      }
    }

    .mp-process {
      circle {
        fill: transparent !important;
      }
    }
  }

  .mp-icon {
    cursor: pointer;

    .mp-hide {
      opacity: 0;
    }
  }

  .mp-player,
  .mp,
  &.mp {
    &_opened {
      #mp-overlay {
        transform: translateY(0%) !important;

        opacity: 1;
      }

      .mp-icon_type-list {
        .mp-hide {
          opacity: 1;
        }
        .mp-show {
          opacity: 0;
        }
      }
    }

    &_played {
      .mp-icon_type-play {
        .mp-hide {
          opacity: 1;
        }
        .mp-show {
          opacity: 0;
        }
      }
    }

    &_muted {
      .mp-icon_type-volume {
        .mp-hide {
          opacity: 1;
        }
        .mp-show {
          opacity: 0;
        }
      }
    }
  }

  svg.mp-icon:not(.mp-icon_type-play) {
    path {
      fill: $white;
    }
  }

  svg.mp-icon_type-play {
    .mp-hide,
    .mp-process {
      display: none;
    }
  }

  .mp_played {
    svg.mp-icon_type-play {
      .mp-hide {
        display: block !important;
      }

      .mp-show {
        display: none !important;
      }

      .mp-process {
        z-index: 10;

        display: block !important;

        opacity: 0 !important;
      }
    }
  }

  .mp_process {
    svg.mp-icon_type-play {
      .mp-hide {
        display: block !important;

        opacity: 0 !important;
      }

      .mp-show {
        display: none !important;
      }

      .mp-process {
        display: block;

        opacity: 1 !important;
      }
    }
  }
}

.mp-player .mp-group,
#mp .mp-group {
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 16px;
  }
}

#mp #mp-panel {
  z-index: $zIndexPlayerPanel;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 0 20px;

  background: $backgroundColor;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.08);

  & > .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1366px;
  }

  .mp {
    &-group {
      &:first-child {
        @media screen and (max-width: $mediaSize) {
          flex-direction: row-reverse;

          & > * + * {
            margin-right: 16px;
            margin-left: 0;
          }
        }
      }

      & + .mp-group {
        margin-left: 24px;
      }
    }

    &-button {
      position: relative;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      cursor: pointer;

      &__icon {
        width: $svgIconSize;
        height: $svgIconSize;
      }

      &__text {
        padding-top: 6px;

        text-align: center;
        white-space: nowrap;

        @media screen and (max-width: $mediaSize) {
          display: none;
        }
      }
    }

    &-song {
      display: flex;

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 176px;

        font-size: 16px;
        line-height: 20px;

        @media screen and (max-width: $mediaSize) {
          display: grid !important;

          width: auto !important;
        }

        &-station {
          font-weight: bold;
        }

        & > * {
          overflow: hidden;

          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &__cover {
        position: relative;

        width: $songPreviewHeight;
        height: $songPreviewHeight;
        margin-right: 16px;
        @media screen and (max-width: $mediaSize) {
          display: none;
        }

        &-image {
          width: 100%;
          height: 100%;

          background-size: contain;
        }

        & > * {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          text-indent: $textIndent;
        }
      }
    }
  }
}

#mp #mp-volume {
  @media screen and (max-width: $mediaSize) {
    display: none;
  }
  &:hover {
    .mp-volume__edit {
      display: block;
    }
  }

  #mp-range {
    position: relative;

    height: 150px;
  }

  .mp-volume__bg {
    padding: 20px 18px;

    border-radius: 4px;
    background-color: #f1f1f1;

    -webkit-filter: drop-shadow(0 -1px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 -1px 6px rgba(0, 0, 0, 0.16));
  }

  .mp-volume__edit {
    position: absolute;
    z-index: 5;
    bottom: 100%;

    display: none;

    padding-bottom: 12px;
  }

  .noUi-base,
  .noUi-target {
    width: 4px;

    background: #cacaca;
  }

  .noUi-connect {
    transition: background 0.45s;

    background: #2f2f2f;
  }

  .noUi-handle {
    position: relative;
    z-index: 1;

    width: 20px;
    height: 20px;

    user-select: none;
    transform: translate(-18px, 30%);

    border-radius: 100%;
    outline: none;
    background-color: #2f2f2f;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.16);

    &:after,
    &:before {
      display: none;
    }
  }
}

#mp #mp-carousel {
  display: flex;
  overflow: hidden;

  margin: 0 60px;

  .mp-hide {
    opacity: 0 !important;
  }

  .mp-show {
    opacity: 1 !important;
  }

  .slick-slide,
  .slick-slide > div,
  .mp-radio__item {
    width: 80px;
    min-width: 80px;
    height: 80px;

    outline: none;

    & + .slick-slide,
    & + .slick-slide > div,
    & + .mp-radio__item {
      margin-left: 5px;
    }
  }

  .mp-radio__item {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    background-color: white;

    .mp-radio__cover {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
      }

      .mp-radio__hover {
        position: absolute;

        width: 48px;
        height: 48px;
        margin: 0 auto;

        opacity: 0;
      }
    }

    &.mp_played {
      .mp-hide {
        opacity: 1 !important;
      }

      .mp-show {
        opacity: 0 !important;
      }
    }

    &:hover {
      .mp-radio__hover {
        opacity: 1;
      }
    }
  }
}

#mp #mp-banner {
  position: fixed;
  z-index: $zIndexBanner;
  top: $songPreviewHeight;
  left: 0;

  display: inline-table;

  width: auto;
  max-width: 1280px;
  margin-left: 50%;
  transform: translateX(-50%);

  .after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background: url('../images/modal-close.svg');
    cursor: pointer;
    top: 25px;
    right: 25px;
  }

  #melon-player-ad-countdown {
    display: inline-flex;

    width: auto;
  }

  * > * {
    display: block;

    width: 100%;
  }
}

.mp-player #mp-overlay,
#mp #mp-overlay {
  position: fixed;
  z-index: $zIndexPlayerOverlay;
  top: 0;
  left: 0;

  width: 100%;
  height: calc(100% - #{$panelHeight});

  transition: $transition;
  transform: translateY(100%);

  opacity: 0;
  background: rgba(white, 0.75);

  .mpl {
    display: flex;

    width: 100%;
    height: 100%;
  }

  .mp {
    &-left,
    &-right {
      overflow: scroll;
      flex-direction: column;

      height: 100%;
    }
  }
}

.mp-player #mp-overlay .mp-left,
#mp #mp-overlay .mp-left {
  box-sizing: border-box;
  width: 55%;
  padding: 32px 32px;

  background-color: hsla(0, 0%, 100%, 0.96);

  & > * + * {
    margin-top: 16px;
  }

  @media screen and (max-width: $mediaSize) {
    width: 100%;
    padding: 16px 96px 16px 16px;
  }

  .mp {
    &-head__info-link,
    &-playlist__link {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 3px 8px;

      vertical-align: top;
      text-decoration: none;

      color: $red;
      border: 1px solid $red !important;
      border-color: $red;
      border-radius: 2px;
      outline: none;

      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      @media screen and (max-width: $mediaSize) {
        margin-top: 12px;
      }
    }
  }

  .mp {
    &-nextplay {
      & > * + *,
      &__items > * + * {
        margin-top: 16px;
      }

      &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      &__item {
        display: flex;
        align-items: center;

        &-info {
          &-artist {
            font-weight: 500;
          }

          &-artist,
          &-song {
            font-size: 16px;
            line-height: 22px;
          }
        }

        &-cover {
          position: relative;

          width: $stationItemImageHeight;
          min-width: $stationItemImageHeight;
          height: $stationItemImageHeight;
          margin-right: 16px;

          img {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: $mediaSize) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }

    &-broadcast {
      display: flex;
      align-items: center;

      &__cover {
        position: relative;

        width: $broadcastCoverHeight;
        height: $broadcastCoverHeight;
        margin-right: 16px;

        &-wrapper {
          width: 100%;
          height: 100%;
        }

        @media screen and (max-width: $mediaSize) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      &__info {
        &-state {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
        }

        &-artist {
          font-weight: 500;
        }

        &-artist,
        &-song {
          font-size: 32px;
          line-height: 38px;

          @media screen and (max-width: $mediaSize) {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }

    &-head {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: 16px;
      }

      &__info {
        display: flex;
        flex-direction: column;

        &-name {
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
        }

        & > * + * {
          margin-top: 12px;
        }
      }

      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        width: $broadcastCoverHeight;

        &-image {
          position: relative;

          width: $logoHeight;
          height: $logoHeight;
        }
      }
    }

    &-playlist {
      margin-top: 32px;

      & > * + * {
        margin-top: 16px;

        @media screen and (max-width: $mediaSize) {
          margin-top: 5px;
        }
      }

      &__title {
        font-size: 31px;
        line-height: 38px;
      }

      &__item {
        position: relative;

        display: flex;

        & > div:last-child {
          display: flex;
          flex-wrap: wrap;

          @media screen and (max-width: $mediaSize) {
            flex-direction: column;
          }
        }

        &_disabled {
          opacity: 0.2;

          * {
            cursor: default;
          }
        }

        & + .mp-playlist__item {
          margin-top: 8px;

          @media screen and (max-width: $mediaSize) {
            margin-top: 20px;
          }
        }

        & > * + * {
          margin-left: 16px;

          @media screen and (max-width: $mediaSize) {
            margin-top: 6px;
            margin-left: 0px;
          }
        }

        &-time {
          font-size: 16px;
          line-height: 26px;
        }

        &-play {
          width: 24px;
          min-width: 24px;
          height: 24px;

          svg {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: $mediaSize) {
            width: 48px;
            min-width: 48px;
            height: 48px;
          }
        }

        &-artist {
          margin-right: 20px;

          font-size: 18px;
          font-weight: 600;
          line-height: 24px;

          @media screen and (max-width: $mediaSize) {
            margin-right: 0px;
          }
        }

        &-song {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

.mp-player #mp-overlay .mp-right,
#mp #mp-overlay .mp-right {
  box-sizing: border-box;
  width: 45%;

  background-color: hsla(0, 0%, 97%, 0.96);

  @media screen and (max-width: $mediaSize) {
    position: absolute;
    top: 0;
    left: calc(100% - 80px);

    width: 100%;

    transition: $transition;

    &:not(.mp-right__open_hide) {
      left: 0;
    }
  }

  &__open {
    display: none;
    align-items: center;

    height: 80px;

    @media screen and (max-width: $mediaSize) {
      display: flex;
    }

    .mp {
      &-right {
        &__text {
          display: inline-block;

          padding: 8px;

          vertical-align: middle;

          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
        }

        &__close {
          position: absolute;
          top: 16px;
          right: 8px;

          width: 48px;
          height: 48px;

          cursor: pointer;

          &:after,
          &:before {
            position: absolute;
            top: 13px;
            left: 22px;

            display: block;

            width: 4px;
            height: 22px;

            content: '';

            border-radius: 4px;
            background-color: #000;
          }

          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }

        &__button {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 80px;
          height: 80px;

          cursor: pointer;

          &:after,
          &:before {
            content: '';
          }

          &:after,
          &:before,
          span {
            display: inline-block;

            width: 8px;
            height: 8px;
            margin: 4px;

            vertical-align: middle;

            border-radius: 50%;
            background-color: #000;
          }
        }
      }
    }
  }

  .mp-stations-list {
    &__item {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 16px 32px;

      cursor: pointer;

      & > div {
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: $mediaSize) {
        height: 80px;
        padding: 0 8px;
      }

      &.active,
      &:hover {
        background: white;

        .mp-stations-list__item-quality {
          opacity: 1;
        }
      }

      &.active,
      &.mp_played,
      &:hover {
        .mp-stations-list__item-cover-hover {
          opacity: 1;
        }
      }

      &-cover {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        width: $stationItemImageHeight;
        min-width: $stationItemImageHeight;
        height: $stationItemImageHeight;
        margin-right: 16px;

        &-hover {
          position: absolute;

          width: 24px;
          height: 24px;
          margin: 0 auto;

          opacity: 0;
        }
      }

      &-info {
        @media screen and (max-width: $mediaSize) {
          display: grid;
        }

        & > * {
          @media screen and (max-width: $mediaSize) {
            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &-artist,
        &-state {
          font-size: 18px;
          line-height: 24px;
        }

        &-state {
          font-weight: 500;
        }
      }

      &-quality {
        display: flex;
        flex-direction: column;

        min-width: 100px;
        margin-left: 20px;

        opacity: 0;

        &-text {
          text-align: center;
        }

        &.active {
          opacity: 1;
          #mp-quality {
            circle {
              fill: black;
              stroke: white;
            }

            path {
              fill: white;
            }
          }
        }

        #mp-quality {
          display: flex;

          width: 48px;
          height: 48px;
          margin-bottom: 5px;

          circle {
            fill: transparent;
            stroke: grey;
          }

          path {
            fill: grey;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mp-player #mp-overlay .mp,
  #mp #mp-overlay .mp {
    &-group {
      margin-bottom: 20px;
    }

    &-group,
    &-left > *,
    &-playlist__item,
    &-nextplay__item {
      align-items: center;
      flex-direction: column;
      justify-content: center;

      text-align: center;

      * + * {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.donate-button {
  display: none;
}

.header-banner {
  img {
    display: block;
  }
}

@media screen and (max-width: 1380px) {
  .header-banner {
    display: none;
  }
  .donate-button {
    display: block;
  }
}