@import '../../css/guideline.scss';

.next-news {
  position: relative;

  display: flex;
  justify-content: space-between;

  color: $black;
  background: $gray;

  @include media(
    (
      flex-direction: (
        all: row,
        tablet: column,
      ),
    )
  );

  .news-list {
    @include media(
      (
        min-width: (
          all: 66.67%,
          tablet: 100%,
        ),
        width: (
          all: 66.67%,
          tablet: 100%,
        ),
      )
    );
  }

  .next-news-title {
    box-sizing: border-box;

    @include media(
      (
        padding: (
          all: 40px,
          mobile: 40px 20px,
        ),
      )
    );

    .line-container {
      width: 100%;
      padding: 0;

      @include media(
        (
          display: (
            all: none,
            tablet: block,
          ),
          margin-top: (
            tablet: 40px,
          ),
        )
      );

      .wrapper {
        padding: 0;
      }
    }

    br {
      @include media(
        (
          display: (
            tablet: none,
          ),
        )
      );
    }
  }
}
