@import '../../css/guideline.scss';

body {
  .tabs-container {
    display: flex;
    background: transparent;

    &.column {
      .wrapper {
        justify-content: flex-start;
        align-self: flex-start;
        flex-direction: column;

        .tab {
          justify-content: flex-start;
          align-items: center;
          margin: 4px 0;
        }
      }
    }

    &.white {
      color: $white;
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;

      .tab-column {
        width: 280px;
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;

        @include media(
          (
            max-width: (
              all: 20vw,
              tablet: 50vw
            ),
            width: (
              all: 280px
            )
          )
        );

        .tab {
          margin: 0;

          & + .tab {
            margin-top: 10px;
          }
        }

        @include media(
          (
            margin-right: (
              all: 80px,
              mobile: 40px,
              smallMobile: 0
            )
          )
        );

        &:last-child {
          margin-right: 0;
        }
      }

      .tab {
        display: flex;
        color: inherit;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        transition: $transition;

        margin: 0 10px;

        &:hover {
          opacity: 0.75;
        }

        &.active {
          opacity: 0.5;
          &:hover {
            opacity: 0.5;
          }
        }

        &:first-child {
          margin-top: 0;
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
