@import '../../css/guideline.scss';

body {
  display: flex;
  flex-direction: column;

  &[data-size='tablet'],
  &[data-size='mobile'] {
    .tracks {
      flex-direction: column;

      .tracks-line {
        padding: 0;
      }
    }
  }

  .tracks {
    display: flex;

    width: 100%;

    background: $black;

    .songs {
      width: 100%;
    }

    .tracks-line {
      display: flex;
      align-items: center;

      margin: 0 10px;
      padding: 40px 0 60px;

      .line {
        min-width: 2px;
        height: 100%;

        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .iframe-wrapper {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  .notification {
    position: fixed;
    z-index: 99999;
    color: white;
    padding: 29px;
    bottom: 80px;
    left: 80px;
    background: rgba(0, 0, 0, 0.8);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 18px #BD5CF8;
    border-radius: 16px;
    width: 332px;
    text-align: center;
    box-sizing: border-box;
    .close {
      position: absolute;
      cursor: pointer;
      right: 16px;
      top: 16px;
    }
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 11px;
      text-transform: uppercase;
      text-shadow: 0px 0px 18px #BD5CF8;
    }
    @keyframes spin { 
      100% { 
        transform:rotate(360deg); 
      } 
    }
    .round-wrapper {
      width: 90%;
      margin-left: 5%;
      position: relative;
      height: 0;
      padding-bottom: 90%;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 11px;
      transform: translateZ(0);
      img, video {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        &.round {
          z-index: 2;
          width: 96%;
          height: 96%;
          position: absolute;
          left: 2%;
          top: 2%;
          animation: spin 8s linear infinite;
        }
      }
    }
    .square-wrapper {
      width: 100%;
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      margin-bottom: 11px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 25px #E8C1FF;
      margin: 45px 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .button {
      width: 100%;
      // margin-left: 10%;
      background: linear-gradient(90.18deg, #6135CD 2.9%, #0A74CE 29.68%, #00C6ED 63.96%, #4793E3 99.92%);
      border: 1px solid #E4F5FF;
      box-shadow: 0px 0px 18px rgba(178, 91, 231, 0.1), 0px 0px 10px rgba(178, 91, 231, 0.3);
      border-radius: 46px;
      cursor: pointer;
      // margin-top: 34px;
      color: white;
      padding: 20px 0;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      outline: none;
      user-select: none;
      font-family: 'Montserrat';
      &:hover {
        border: 1px solid #E4F5FF;
        // padding: 17px 0;
        // width: calc(80% - 6px);
        filter: drop-shadow(0px 0px 18px #3E66F7);
      }
    }
  }
}

@media screen and (max-width: 650px) { 
  body {
    .notification {
      width: 90%;
      left: 5%;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}

.donate-button {
  background: #1D0042;
  position: relative;
  a {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: right;
  }
  img {
    display: block;
    max-width: 100%;
  }
}
