@import '../../css/guideline.scss';

body {
  .news-list-infinite {
    position: relative;

    display: flex;
    flex-direction: column;

    .not-found {
      width: calc(100% - calc(4 * 1.4vw));
      padding: calc(2 * 1.4vw) calc(2 * 1.4vw) calc(2 * 1.4vw + 68px);
    }
  }
}
