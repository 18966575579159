@import '../../css/guideline.scss';

body {
  .clips {
    .news-card {
      @include media(
        (
          width: (
            all: 66.66vw !important,
            mobile: 100vw !important
          )
        )
      );

      .news-card {
        &__content {
          .information,
          .information .additional {
            position: relative !important;
          }

          .additional {
            margin-top: 0;
          }

          &-wrapper {
            padding-bottom: 0% !important;

            @include media(
              (
                height: (
                  all: 66.66vw * 0.5,
                  tablet: calc(30.5vw + 80px),
                  mobile: 100vw
                )
              )
            );
          }
        }
      }
    }
  }
}
