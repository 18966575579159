@import '../../css/guideline.scss';

body {
  &[data-browser='ie'] {
    .presenter-body {
      .wrapper {
        .presenter-column {
          .presenter-info {
            justify-content: flex-start;
          }

          .presenter-description {
            height: auto !important;
            flex: none;

            &:after {
              opacity: 0 !important;
            }
          }
        }
      }
    }
  }

  .presenter-body {
    color: $white;
    padding-bottom: 60px;
    background-color: $black;

    .section-title {
      flex: none;
      white-space: nowrap;
      border-bottom: 2px solid $gray;

      .wrapper {
        padding: 0;
      }
    }

    .line-container {
      .wrapper {
        padding: 0;
        margin: 0;
      }
    }

    .wrapper {
      display: flex;

      @include media(
        (
          flex-direction: (
            all: row,
            tablet: column
          )
        )
      );

      .presenter-column {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        @include media(
          (
            width: (
              all: 66.66%,
              tablet: 100%
            )
          )
        );

        &:first-child {
          @include media(
            (
              border-bottom: (
                tablet: 2px solid $white
              )
            )
          );

          .section-title {
            border-color: transparent;
          }
        }

        &:last-child {
          width: 33.34%;
          margin-left: 40px;

          @include media(
            (
              width: (
                all: 33.34%,
                tablet: 100%
              ),
              margin-left: (
                all: 40px,
                tablet: 0
              )
            )
          );

          .presenter-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:not(.is-open) {
              .presenter-description {
                &:after {
                  opacity: 1;
                }
              }
            }

            &.is-open {
              .section-link {
                .icon {
                  transform: rotate(-180deg);
                }
              }
            }
          }

          @include media(
            (
              margin-left: (
                all: 40px,
                tablet: 0
              )
            )
          );
        }

        .presenter-description {
          position: relative;
          margin: 30px 0;
          flex: 1 1 auto;

          &:after {
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100%;
            content: '';
            height: 100px;
            display: block;
            position: absolute;
            transition: $transition;
            background: linear-gradient(to top, $black, rgba(0, 0, 0, 0));
          }
        }

        .section-link-container {
          padding: 60px 0 0;
        }

        .presenter-shows {
          .show {
            display: flex;
            flex-direction: row;
            padding: 30px 0;

            height: 400px;
            background: $blue;

            &:not(:last-child) {
              border-bottom: 2px solid $gray;
            }

            .show-column {
              flex-basis: 66.6%;

              &:first-child {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              }

              &:last-child {
                display: flex;
                flex-basis: 33.3%;
                justify-content: flex-end;
              }

              .show-name {
                color: $white;
                cursor: pointer;
              }

              .show-image {
                width: 200px;
                height: 200px;
                min-width: 200px;
                overflow: hidden;

                transition: $transition;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transition: $transition;
                }
              }
            }
          }
        }
      }
    }
  }
}
