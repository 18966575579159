@import '../../css/guideline.scss';

body {
  .second-level-page {
    .banner {
      position: relative;

      overflow: hidden;

      transition: $transition;

      background-color: $white;

      img {
        width: 100%;
        height: 100%;

        transition: $transition;

        object-fit: cover;
      }

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 30%;

        transform: translate(-50%, -50%);

        svg {
          position: inherit;

          width: 100%;
          height: 100%;
        }

        p {
          text-align: center;
          text-transform: uppercase;

          color: $white;
        }
      }
    }
  }

  .static-page {
    .section-title {
      .anchors-container {
        display: flex;

        @include media(
          (
            flex-direction: (
              all: row,
              tablet: column,
            ),
            margin-top: (
              mobile: 20px,
            ),
            margin-left: (
              tablet: 60px,
            ),
          )
        );

        .anchors-column {
          display: flex;
          flex-direction: column;

          &:last-child {
            @include media(
              (
                margin-left: (
                  all: 180px,
                  tablet: 0,
                ),
                margin-top: (
                  tablet: 20px,
                ),
              )
            );
          }

          a {
            cursor: pointer;

            color: $white;
          }

          a + a {
            margin-top: 20px;
          }
        }
      }
    }

    .wrapper {
      .static-container {
        section {
          overflow: hidden;

          padding-top: 60px;

          .static-wrap {
            display: flex;

            width: 100%;
            padding-top: 40px;

            border-top: 2px solid $black;

            @include media(
              (
                flex-direction: (
                  tablet: column,
                ),
              )
            );

            .static-column {
              display: flex;
              flex-direction: column;

              &:first-child {
                width: 400px;
                min-width: 400px;
                max-width: 400px;

                @include media(
                  (
                    margin-bottom: (
                      tablet: 40px,
                    ),
                    word-break: (
                      all: break-all,
                      mobile: break-word,
                    ),
                  )
                );
              }

              &:last-child {
                width: 100%;
                margin-left: 40px;

                @include media(
                  (
                    margin-left: (
                      all: 40px,
                      tablet: 0,
                    ),
                  )
                );
              }

              > div + div {
                margin-top: 40px;
              }

              li + li,
              p + p {
                margin-top: 40px;
              }

              .static-contacts {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                margin-top: 0;
                margin-right: -100px;

                @include media(
                  (
                    flex-direction: (
                      all: row,
                      tablet: column,
                    ),
                  )
                );

                > div {
                  display: flex;
                  flex-direction: column;

                  margin-top: 40px;
                  margin-right: 100px;
                }
              }

              strong {
                font-weight: 500;
              }
              
              small {
                padding-top: 12px;

                color: $darkGray;

                a {
                  color: $darkGray;
                }
              }
            }
          }

          ul:not(.expanded-list),
          ol {
            list-style: decimal;
            list-style-position: inside;

            li > p {
              display: inline;
            }
          }
        }

        .svg-image {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
