@import '../../css/guideline.scss';

body {
  .show-head.sticky {
    min-height: initial;
    padding: 0;

    background: transparent !important;

    .poster-container {
      width: 100%;
      min-width: 100%;
      max-width: 100%;

      background: transparent;

      .schedule {
        position: absolute;
        bottom: 40px;
        left: 20px;

        display: flex;

        .date {
          width: 195px;
          height: 195px;
        }
      }
    }
  }

  .show-head {
    position: relative;

    display: flex;
    overflow: hidden;

    box-sizing: border-box;

    background: $red;

    @include media(
      (
        flex-direction: (
          all: row,
          tablet: column,
        ),
        padding: (
          all: 40px 0,
          tablet: 0,
        ),
      )
    );

    .content {
      box-sizing: border-box;

      @include media(
        (
          padding: (
            tablet: 20px,
          ),
          width: (
            all: 40%,
            tablet: 100%,
          ),
          min-width: (
            all: 440px,
            tablet: initial,
          ),
        )
      );

      .show-title {
        display: flex;
        flex-direction: column;

        box-sizing: border-box;

        @include media(
          (
            width: (
              tablet: 100%,
            ),
            align-items: (
              tablet: flex-end,
              mobile: flex-start,
            ),
            padding: (
              tablet: 40px,
              mobile: 20px,
            ),
            position: (
              all: static,
              tablet: absolute,
            ),
            right: (
              tablet: 0,
            ),
            top: (
              tablet: 0,
            ),
          )
        );

        .schedule {
          display: flex;

          @include media(
            (
              position: (
                all: absolute,
                mobile: static,
              ),
              top: (
                all: 40px,
                tablet: 40px,
              ),
              left: (
                all: 40px,
                tablet: 40px,
              ),
            )
          );
        }

        .presenters {
          display: flex;

          margin-top: 20px;

          @include media(
            (
              margin-bottom: (
                all: 20px,
                tablet: 0,
              ),
            )
          );

          .presenter {
            overflow: hidden;

            width: 80px;
            min-width: 80px;
            height: 80px;

            border-radius: 100%;
            background-color: $red;
            background-repeat: no-repeat;
            background-position: center 0;
            background-size: cover;

            & + .presenter {
              margin-left: 20px;
            }
          }
        }
      }

      .description {
        margin-bottom: 20px;

        font-size: 16px;
        font-weight: 300;
        line-height: 20px;

        @include media(
          (
            margin-top: (
              all: 20px,
              mobile: 0px,
            ),
            margin-bottom: (
              all: 20px,
              mobile: 0px,
            ),
          )
        );

        &:first-child {
          margin-top: 0;
        }
      }

      .button {
        box-sizing: border-box;
        width: 240px;
        height: 70px;
        margin-top: 20px;

        color: $white;
        background: $red;
      }
    }

    .poster-container {
      display: flex;

      width: 100%;

      background: transparent;

      @include media(
        (
          margin: (
            all: 0 20px 0 40px,
            tablet: 0 auto,
          ),
          width: (
            all: 60%,
            tablet: calc(100% - 200px),
            mobile: calc(100% - 40px),
          ),
          padding-top: (
            mobile: 280px,
          ),
        )
      );

      .poster {
        position: relative;

        width: 100%;
        height: 0;
        margin: 0 auto;
        padding-top: $showPosterProportion;
      }
    }
  }
}
