@import './guideline.scss';

// common
body[data-browser='ie'] {
  .logotype-component {
    position: relative;
    min-height: 20px;
    min-width: 20px;

    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  * {
    transition: 0ms !important;
  }

  .authorization-society {
    .svg-image {
      > div {
        display: block;
      }

      svg {
        width: 80px !important;
      }
    }
  }
}

// player
body[data-browser='ie'] {
  .mp-player.fixed,
  .mp-player.only-fixed {
    #mp-app {
      $paddingDesktop: #{$playerLeftPaddingRightDesktop} + #{$playerLeftPaddingLeftDesktop} +
        2px;

      $paddingTablet: #{$playerLeftPaddingRightTablet} + #{$playerLeftPaddingLeftTablet} +
        2px;
      $paddingMobile: #{$playerLeftPaddingRightMobile} + #{$playerLeftPaddingLeftMobile} +
        2px;

      $paddingSmallMobile: #{$playerLeftPaddingRightSmallMobile} + #{$playerLeftPaddingLeftSmallMobile} +
        2px;

      @include media(
        (
          left: (
            all: calc((#{$paddingDesktop}) + (#{$playerLeftDesktop})),
            tablet: calc((#{$paddingTablet}) + (#{$playerLeftTablet})),
            mobile: calc((#{$paddingMobile}) + (#{$playerLeftMobile})),
            smallMobile:
              calc((#{$paddingSmallMobile}) + (#{$playerLeftSmallMobile}))
          ),
          min-width: (
            smallMobile: 100vw / 2
          ),
          width: (
            all:
              calc(
                100% - (#{$playerRightDesktop}) - (#{$playerLeftDesktop}) -
                  ((#{$paddingDesktop})) + 140px
              ),
            tablet:
              calc(
                100% - (#{$playerRightTablet}) - (#{$playerLeftTablet}) -
                  (#{$paddingTablet}) + 100px
              ),
            mobile:
              calc(
                100% - (#{$playerRightMobile}) - (#{$playerLeftMobile}) -
                  (#{$paddingMobile})
              ),
            smallMobile:
              calc(
                100% - (#{$playerRightSmallMobile}) -
                  (#{$playerLeftSmallMobile}) - (#{$paddingSmallMobile})
              )
          ),
          max-width: (
            all:
              calc(
                100% - (#{$playerRightDesktop}) - (#{$playerLeftDesktop}) -
                  ((#{$paddingDesktop})) + 140px
              ),
            tablet:
              calc(
                100% - (#{$playerRightTablet}) - (#{$playerLeftTablet}) -
                  (#{$paddingTablet}) + 100px
              ),
            mobile:
              calc(
                100% - (#{$playerRightMobile}) - (#{$playerLeftMobile}) -
                  (#{$paddingMobile})
              ),
            smallMobile:
              calc(
                100% - (#{$playerRightSmallMobile}) -
                  (#{$playerLeftSmallMobile}) - (#{$paddingSmallMobile})
              )
          )
        )
      );
    }

    .mp-custom-left {
      .logotype-component {
        @include media(
          (
            height: (
              tablet: 25px,
              mobile: 60px
            )
          )
        );
      }
    }

    .mp-custom-right {
      .live-control {
        display: none;
      }

      @include media(
        (
          width: (
            all: $playerRightDesktop - 140px,
            tablet: $playerRightTablet - 100px,
            smallMobile: $playerRightSmallMobile
          )
        )
      );
    }
  }
  .live-player {
    display: none;
  }

  .section-title {
    .checkbox-block {
      display: initial;
      > div:last-child {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}
