@import '../../css/guideline.scss';

body {
  .cross {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    width: 40px;
    height: 40px;

    cursor: pointer;
    transition: $transition;

    .line {
      position: absolute;
      top: 50%;

      width: 100%;
      height: 2px;

      transition: $transition;

      background-color: $darkGray;

      &:first-child {
        left: 0;

        transform: scale(1.25) rotate(45deg);
      }

      &:last-child {
        right: 0;

        transform: scale(1.25) rotate(135deg);
      }
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}
