@import '../../css/guideline.scss';

body {
  .navigation-links {
    .category {
      .title {
        transition: $transition;
        color: $white;
        display: flex;

        &.active-route {
          color: $red;
        }

        &:hover {
          color: $red;
        }
      }

      .links {
        width: 100%;
        padding-right: 30px;
        display: inline-flex;
        flex-direction: column;
        box-sizing: border-box;

        .link {
          transition: $transition;

          &.active-route {
            color: $red;
          }

          & + .link {
            margin: 20px 0 0 0;
          }

          p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &:hover {
            color: $red;
          }

          &:first-child {
            margin-top: 0;
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
