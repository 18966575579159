@import '../../css/guideline.scss';

body {
  .navigation-links {
    display: flex;

    @include media(
      (
        justify-content: (
          all: space-between,
          laptop: flex-start
        ),
        flex-wrap: (
          all: nowrap,
          laptop: wrap
        )
      )
    );

    &.is-static-navigation {
      @include media(
        (
          margin-top: (
            laptop: 20px,
            tablet: 40px,
            mobile: 50px
          ),
          align-items: (
            all: center,
            mobile: flex-start
          ),
          flex-direction: (
            mobile: column
          )
        )
      );

      .category {
        margin: 0;
        width: auto;
        min-width: initial;
        max-width: initial;

        .links {
          display: flex;
          padding-right: initial;

          @include media(
            (
              flex-direction: (
                all: row,
                mobile: column
              )
            )
          );
        }

        .link {
          & + .link {
            @include media(
              (
                margin: (
                  all: 0 0 0 10px,
                  mobile: 20px 0 0 0
                )
              )
            );
          }
        }
      }
    }

    &.is-menu-navigation {
      flex-wrap: wrap;
      justify-content: flex-start;

      .category {
        box-sizing: border-box;

        @include media(
          (
            min-width: (
              all: 16.666%,
              tablet: calc(33.33% - 40px / 3),
              mobile: 100%
            ),
            width: (
              all: 16.666%,
              tablet: calc(33.33% - 0px / 3),
              mobile: 100%
            ),
            max-width: (
              all: 16.666%,
              tablet: calc(33.33% - 0px / 3),
              mobile: 100%
            ),
            padding: (
              all: 0 20px 80px,
              mobile: 0
            )
          )
        );

        &:first-child {
          padding-top: 0;
          padding-left: 0;
        }

        &:nth-child(3) {
          @include media(
            (
              padding-right: (
                tablet: 0
              )
            )
          );
        }

        &:nth-child(4) {
          @include media(
            (
              padding-left: (
                tablet: 0
              )
            )
          );
        }

        &:last-child {
          padding-right: 0;
        }

        .category-title {
          @include media(
            (
              cursor: (
                mobile: pointer
              ),
              justify-content: (
                mobile: space-between
              )
            )
          );

          & > .svg-image {
            width: 40px;

            @include media(
              (
                display: (
                  all: none,
                  mobile: block
                )
              )
            );
          }

          .title {
            align-items: center;
            text-transform: none;
            justify-content: space-between;

            @include media(
              (
                margin-bottom: (
                  all: 40px,
                  mobile: 0
                )
              )
            );

            .svg-image {
              svg {
                width: 23px;
                height: 11px;
                stroke: $white;

                @include media(
                  (
                    display: (
                      all: none,
                      mobile: flex
                    )
                  )
                );
              }
            }
          }
        }

        .line {
          @include media(
            (
              display: (
                all: none,
                mobile: block
              ),
              height: (
                mobile: 2px
              ),
              background: (
                mobile: $darkGray
              )
            )
          );
        }

        .links {
          @include media(
            (
              padding-bottom: (
                mobile: 20px
              )
            )
          );

          .link {
            width: 100%;
          }
        }
      }
    }

    .category {
      @include media(
        (
          width: (
            all: 16.666%,
            laptop: 25%,
            mobile: 100%
          ),
          min-width: (
            all: 16.666%,
            laptop: 25%,
            mobile: 100%
          ),
          padding-bottom: (
            all: 100px,
            laptop: 100px,
            mobile: 80px
          )
        )
      );

      &.open {
        .svg-image.arrow {
          transform: rotate(-180deg);
        }
      }

      .svg-image.arrow {
        transition: $transition;
      }

      .category-title {
        display: flex;
        position: relative;
        justify-content: flex-start;

        .svg-symbol {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          margin-right: 10px;

          svg {
            path {
              &:last-child {
                fill: $black;
              }
            }
          }
        }
      }

      .line {
        height: 2px;
        width: 100%;
        background: $veryLightBlack;

        @include media(
          (
            margin: (
              all: 40px 0,
              mobile: 20px 0
            )
          )
        );
      }
    }
  }
  .category-user {
    width: 100%;
    font-size: 20px;
    color: white;
    font-size: 20px;
    display: none;
    &.opened {
      .menu-sub {
        padding-bottom: 20px;
        height: 72px;
      }
    }
    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .line {
      height: 2px;
      background: #959ca4;
      margin: 20px 0;
    }
    .menu-sub {
      transition: 0.2s;
      padding-bottom: 0px;
      height: 0px;
      position: relative;
      overflow: hidden;
    }
    .item {
      margin: 20px 0 0 0;
      letter-spacing: 0.5px;
      font-size: 16px;
      color: #959ca4;
    }
  }
  @media screen and (max-width: 720px) {
    .category-user {
      display: block;
    }
  }
}
