.infinite-loader {
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  height: 0;

  transition: all 200ms;

  background: rgba(#ede9e6, 0.85);

  &.show {
    height: 68px;
  }

  & > * {
    opacity: 0.5;
  }

  .svg-image {
    margin-right: 12px;

    svg {
      width: 18px !important;

      stroke: black;
    }
  }
}
