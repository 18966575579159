@import '../../css/guideline.scss';

$SLIDER_PADDING: 30px;

.contest.in-slider {
  display: flex;
  align-items: flex-end;

  height: 100%;

  .content {
    position: relative;

    width: 100%;
    margin-bottom: 40px;

    @include media(
      (
        margin-bottom: (
          mobile: 20px
        )
      )
    );
    @include media(
      (
        padding: (
          all: calc(#{$HEADER} + #{$SLIDER_PADDING}) 40px 0,
          mobile: calc(#{$HEADER} + #{$SLIDER_PADDING}) 20px 0
        )
      )
    );

    .section-link-container,
    .line-container {
      background: transparent;

      .wrapper {
        padding: 0;
      }
    }

    .section-link-container {
      margin-top: 40px;
      .section-link {
        justify-content: flex-start;
      }

      .title-container {
        min-width: auto;

        .title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.contest {
  position: relative;

  display: flex;

  width: 100%;

  background: $blue;

  &:not(.in-slider) {
    @include media(
      (
        padding-bottom: (
          all: $newsWithBackgroundImageProportion,
          mobile: $newsWithBackgroundImageProportionMobile
        )
      )
    );
  }

  .content {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    box-sizing: border-box;
    width: 100%;

    @include media(
      (
        padding: (
          all: 40px,
          mobile: 20px
        )
      )
    );

    .title {
      color: $white;

      @include media(
        (
          margin-bottom: (
            all: 40px,
            mobile: 20px
          ),
          max-width: (
            all: 600px,
            tablet: initial,
            mobile: 95%
          )
        )
      );
    }

    a {
      display: inline-flex;
    }

    .button {
      padding: 0;

      border: none;

      @include media(
        (
          width: (
            all: 240px,
            mobile: 200px
          ),
          height: (
            all: 70px,
            mobile: 60px
          )
        )
      );
    }
  }

  .poster {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    user-select: none;
    pointer-events: none;

    background-repeat: no-repeat;
    background-size: cover;

    @include media(
      (
        background-position: (
          all: left 0,
          mobile: right 100%
        )
      )
    );
  }
}
