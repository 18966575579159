@import '../../css/guideline.scss';

body {
  .menu {
    position: fixed;
    z-index: $zIndexMenu;
    top: -100vh;
    top: calc(var(--vh, 1vh) * -100);
    left: 0;

    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    transition: $menuTransition;

    .modal-body {
      position: relative;
      z-index: 10;

      overflow-x: hidden;
      overflow-y: auto;

      max-height: 100vh;
      max-height: calc(var(--vh, 1vh) * 100);
      padding-bottom: 80px;

      background: $black;

      @include media(
        (
          min-height: (
            mobile: calc(var(--vh, 1vh) * 100)
          ),
          padding-bottom: (
            all: 80px,
            tablet: 60px,
            mobile: 40px
          )
        )
      );

      .frequency {
        margin-bottom: 40px;
      }

      .title-block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media(
          (
            padding: (
              all: 20px 20px 40px,
              mobile: 40px
            ),
            width: (
              all: calc(100% - 40px),
              mobile: calc(100% - 80px)
            )
          )
        );

        .controls {
          display: flex;
          align-items: center;

          height: 40px;

          @include media(
            (
              justify-content: (
                mobile: space-between
              ),
              flex: (
                mobile: 2
              )
            )
          );
          @include media(
            (
              width: (
                mobile: 100%/3
              )
            )
          );

          & > .user-component,
          & > .cross,
          & > .search {
            width: 30px;
            height: 30px;
            margin: 0 20px;

            &.login {
              width: 40px;
              height: 40px;
            }

            div {
              width: 100%;
              height: 100%;
            }

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          .cross {
            &:hover {
              span {
                background: $white;
              }
            }

            span {
              transition: $transition;

              background: $darkGray;
            }
          }

          svg {
            width: 100%;
            height: 100%;

            path {
              transition: $transition;

              stroke: $darkGray;
            }

            &:hover {
              path {
                stroke: $white;
              }
            }
          }
        }

        .logo {
          @include media(
            (
              flex: (
                mobile: 1
              )
            )
          );

          .logotype-component {
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
              opacity: 1;

              fill: $white;
              @include media(
                (
                  width: (
                    all: 120px,
                    mobile: 40px
                  )
                )
              );
            }

            & > div {
              display: grid;
            }
          }
        }
      }

      .menu-wrapper {
        @include media(
          (
            padding: (
              all: 0 120px,
              laptop: 0 20px,
              tablet: 0 80px,
              mobile: 0 40px,
              // laptop: 0 100px
            )
          )
        );

        .download-buttons {
          margin-bottom: 40px;
        }

        .information {
          display: flex;

          @include media(
            (
              justify-content: (
                all: space-between,
                tablet: flex-start
              ),
              align-items: (
                all: center,
                tablet: flex-start
              ),
              flex-direction: (
                tablet: column
              ),
              margin-top: (
                mobile: 40px
              )
            )
          );

          & > div:nth-child(1) {
            display: flex;

            @include media(
              (
                align-items: (
                  all: center,
                  laptop: flex-start
                ),
                flex-direction: (
                  laptop: column
                )
              )
            );
          }

          .society-block {
            display: flex;
            align-items: center;

            margin-right: 40px;

            a {
              margin: 0 10px;

              @include media(
                (
                  width: (
                    mobile: calc(50% - 10px)
                  )
                )
              );

              &:hover {
                svg {
                  path {
                    fill: $red;
                  }
                }
              }

              svg {
                width: 20px;
                height: 20px;

                path {
                  transition: $transition;

                  fill: $darkGray;
                }
              }

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .fade {
      position: fixed;
      z-index: 0;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transition: all 500ms ease;

      opacity: 0;
      background: $black;
    }

    &.visible {
      top: 0;

      .fade {
        opacity: 0.75;
      }
    }
  }
}
