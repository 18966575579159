@import '../../css/guideline.scss';

body {
  .award-block {
    .section-link-container {
      &.active {
        .icon {
          transform: rotate(-180deg);
        }
      }
    }

    .award {
      width: 100%;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;

      @include media(
        (
          padding: (
            all: 40px,
            mobile: 20px
          ),
          flex-direction: (
            all: row,
            mobile: column
          )
        )
      );

      &[data-theme='black'] {
        color: $white;
        background: $black;
      }

      & > div {
        width: 100%;
        display: flex;
        overflow: hidden;
        flex-direction: column;

        @include media(
          (
            margin: (
              all: 0 30px,
              mobile: 0
            )
          )
        );

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .information {
        & > .title {
          margin-bottom: 120px;
        }

        .paragraphs {
          .paragraph {
            .title {
              margin-bottom: 20px;
            }

            .subtitle {
              font-weight: 300;
            }

            & + .paragraph {
              margin-top: 60px;
            }
          }
        }
      }

      .description {
        flex: 1 1 auto;
        position: relative;

        &:after {
          right: 0;
          bottom: 0;
          opacity: 1;
          width: 100%;
          content: '';
          height: 100px;
          display: block;
          position: absolute;
          transition: $transition;
          background: linear-gradient(to top, $black, rgba(0, 0, 0, 0));
        }

        &.show {
          &:after {
            opacity: 0;
          }
        }

        & > div {
          flex: 1 1 auto;
        }

        div {
          font-weight: 300;

          & + div {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
