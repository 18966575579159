@import './media.scss';

$COLUMN: 120px;
$HEADER: 100px;
$SLIDER_DOT: 70px;

// ----------

// 1890x890
$newsWithBackgroundImageProportion: 47.08994708994709%;
$newsWithBackgroundImageProportionMobile: 150%;

// 1750x1320
$showPosterProportion: 75.42857142857143%;

// ----------

$zIndexApp: 1000;
$zIndexLive: 1005;
$zIndexSlider: 1003;
$zIndexHeader: 1004;
$zIndexAdWolfBanner: 99;
$zIndexMultiPlayer: 1030;
$zIndexOverlayPlayer: 100;

$zIndexMenu: 9997;
$zIndexModal: 9998;
$zIndexCities: 9999;
$zIndexLoader: 10000;

// ----------

$overlayOpacity: 0.15;

$red: #e22b2d;
$blue: #070497;
$gray: #ede9e6;
$black: #000000;
$white: #ffffff;
$darkGray: #959ca4;
$lightBlack: #191919;
$veryLightBlack: #494c4f;
$newsHoverBackground: #070497;

$vk: #4e76a1;
$fb: #3c5b96;
$ok: #ef7020;
$twitter: #61aadb;
$youtube: #ff0000;
$website: #10008c;
$instagram: radial-gradient(
  circle at 33% 100%,
  #fed373 4%,
  #f15245 30%,
  #d92e7f 62%,
  #9b36b7 85%,
  #515ecf
);

// ----------

$transition: all 250ms;
$menuTransition: all 250ms;

$lightBorder: 2px solid rgba(255, 255, 255, 0.1);
$darkBorder: 2px solid rgba(0, 0, 0, 0.1);

// Radio player

$playerHeight: 80px;
$playerIconHeight: 60px;
$playerTransition: all 750ms;

$playerRightDesktop: 222px;
$playerRightTablet: 182px;
$playerRightMobile: 82px;
$playerRightSmallMobile: 25vw;

$playerLeftDesktop: 220px;
$playerLeftTablet: 100px;
$playerLeftMobile: 60px;
$playerLeftSmallMobile: 25vw;

$playerLeftPaddingRightDesktop: 20px;
$playerLeftPaddingLeftDesktop: 20px;
$playerLeftPaddingRightTablet: 10px;
$playerLeftPaddingLeftTablet: 20px;
$playerLeftPaddingRightMobile: 10px;
$playerLeftPaddingLeftMobile: 10px;
$playerLeftPaddingRightSmallMobile: 0px;
$playerLeftPaddingLeftSmallMobile: 0px;
