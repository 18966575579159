@import '../../css/guideline.scss';

.smart-input {
  position: relative;

  cursor: text;
  transition: border-color 250ms;

  border-bottom: 2px solid transparent;
  background: $white;

  @include media(
    (
      width: (
        all: 100%,
        tablet: calc(100% - 40px)
      ),
      margin: (
        all: 0 10px,
        mobile: 10px 0
      ),
      padding: (
        all: 26px 20px calc(26px - 2px)
      )
    )
  );

  .smart-input-content {
    display: flex;
  }

  &[data-type='checkbox'] {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 0;

    cursor: pointer;

    border: none;
    background: transparent;

    &.js-error {
      padding: 0;
    }

    .error-message {
      margin-bottom: 10px;
    }

    &.disabled {
      .checkbox {
        background: $darkGray;
      }
    }

    input {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;

      display: initial;
      flex: none;

      width: 100%;
      height: 100%;

      user-select: none;
      pointer-events: none;

      opacity: 0;

      font-size: 16px;

      &:checked {
        & + .checkbox {
          border-color: $black;
          background: $black;

          &:hover {
            border-color: $black;
            background: $black;
          }
        }
      }
    }

    .checkbox {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      margin-right: 20px;

      cursor: pointer;
      transition: $transition;

      border-width: 2px;
      border-style: solid;
      border-color: $lightBlack;

      &:hover {
        border-width: 5px;
      }
    }

    .placeholder {
      a {
        margin-left: 5px;

        cursor: pointer;
        transition: $transition;

        color: $vk;

        &:hover {
          color: $fb;
        }
      }
    }
  }

  &:hover {
    border-color: $lightBlack;
  }

  &:first-child {
    margin-top: 0;
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  .error-message {
    overflow: hidden;

    max-height: 0;

    transition: $transition;
    word-break: break-word;

    color: $darkGray;
  }

  input {
    flex: 1;

    width: 100%;

    border: none;
    outline: none;

    font-size: 16px;
  }
}

.smart-input.js-error {
  padding: 19px 20px calc(18px - 2px);
  &:hover {
    border-color: $red;
  }

  .error-message {
    overflow: hidden;

    max-height: inherit;
  }

  input,
  .placeholder,
  .placeholder a {
    color: $red;
    &::-moz-placeholder {
      color: $red;
    }
    &::-webkit-input-placeholder {
      color: $red;
    }
  }
}
