@import '../../css/guideline.scss';

body {
  .checkbox-block {
    display: flex;
    flex-direction: row;

    &.news-page-checkbox {
      // width: calc(((100% - 80px) * 0.4) - 20px);

      & > div {
        @include media(
          (
            width: (
              all: 360px,
              laptop: 240px,
              tablet: 160px
            )
          )
        );
      }
    }

    & > div {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-left: 20px;
      }
    }
  }
}
