@import '../../css/guideline.scss';

$padding: 10px;

body {
  .select {
    position: relative;
    z-index: 100;

    width: 240px;

    cursor: pointer;

    .select__indicators {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      box-sizing: border-box;
    }

    &__value-container {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 2px 8px;
      -webkit-overflow-scrolling: touch;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      div {
        color: white;
      }
    }

    &__indicator {
      color: rgb(204, 204, 204);
      display: flex;
      box-sizing: border-box;
      padding: 8px;
      transition: color 150ms ease 0s;

      svg {
        fill: white;
      }
    }

    &__control {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: default;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      min-height: 38px;
      outline: 0 !important;
      position: relative;
      -webkit-transition: all 100ms;
      transition: all 100ms;
      box-sizing: border-box;

      border: none;
      background: none;
      box-shadow: none;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      margin: 0;

      &-notice {
        color: white;
      }
    }

    &__option {
      cursor: pointer;

      &--is-selected {
        background: transparent;
      }

      &--is-focused,
      &:active {
        background: black;
      }
    }

    &.blue {
      .select {
        &__menu {
          margin: 0;

          background: $blue;
        }
      }
    }

    &.red {
      .select {
        &__menu {
          margin: 0;

          background: $red;
        }
      }
    }
  }
}
