@import "../../css/guideline.scss";

body {
  .modal[data-modal="InterviewModal"] {
    .content {
      box-sizing: border-box;
      width: 90%;
      max-width: 600px;

      .close {
        display: none;
      }
    }

    .buttons-wrap {
      display: flex;
      flex-wrap: wrap;

      margin-top: 30px;

      .button {
        box-sizing: border-box;
        height: 70px;
        padding: 0;

        transition: $transition;
        text-transform: uppercase;

        color: $white;
        background: $black;

        &:not(.cancel) {
          &:hover {
            background: $lightBlack;
          }
        }
      }

      .cancel {
        padding: 0 10px;

        color: $black;
        background: transparent;
        box-shadow: none;
      }

      & > *,
      .button {
        display: flex;
        flex-grow: 1;
      }
    }
  }
}
