html {
  -ms-overflow-style: none;
}

.scroll-customise {
  &::-webkit-scrollbar {
    width: 0;
  }
  *::-webkit-scrollbar {
    width: 0;
  }
}
