@import '../../css/guideline.scss';

body {
  .borders-block {
    background: transparent;

    &.gray {
      background: $gray;
    }
  }

  .smart-flex-block {
    display: flex;

    background: transparent;

    @include media(
      (
        flex-direction: (
          all: row,
          tablet: column-reverse
        )
      )
    );

    &.gray {
      background: $gray;
    }
  }
}
