@import '../../css/guideline.scss';

.songs-carousel {
  &.slick-ssr {
    .slick-slide {
      width: 100%;
      min-width: 100%;
    }

    .songs-list-item {
      @include media(
        (
          flex-direction: (
            tablet: column
          )
        )
      );
    }

    .line {
      @include media(
        (
          display: (
            tablet: none
          )
        )
      );
    }
  }
}

body {
  .songs {
    width: 100%;
    color: $white;
    background: $black;
    padding: 40px 0 60px;

    &.gray {
      color: $black;
      background: $gray;

      .songs-carousel {
        .line {
          background: $black;
        }
      }
    }

    .section-title,
    .section-link-container {
      .wrapper {
        padding: 0;
      }
    }

    &.only-song-list {
      margin: 0;
      padding: 0;
      .songs-list {
        margin: 0;
      }
    }

    &.no-padding {
      padding-top: 0;
      padding-bottom: 0;

      .songs-list {
        margin: 0;
      }

      .slick-slider {
        margin: 0;
      }
    }

    .songs-carousel {
      margin: 40px 0 60px;

      .songs-list-item {
        width: 100%;
        outline: none;
        align-items: center;
        display: flex !important;
        justify-content: space-between;

        .songs-list {
          margin: 0;
        }
      }
    }

    .line {
      width: 2px;
      opacity: 0.1;
      height: 100%;
      margin: 0 40px;
      min-width: 2px;
      background: $white;
    }

    .songs-list {
      width: 100%;
      margin: 40px 0 60px;

      &.no-link {
        margin-bottom: 0;
      }
    }
  }
}
