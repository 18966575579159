@import '../../css/guideline.scss';

body {
  .society {
    cursor: pointer;
    min-height: calc(100vw / 4);
    position: relative;
    background: #f2f2f2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(
      (
        min-height: (
          all: calc(100vw / 4),
          tablet: calc(100vw / 2)
        ),
        height: (
          all: calc(100vw / 4),
          tablet: calc(100vw / 2)
        )
      )
    );

    &:hover {
      .icon {
        .preset {
          opacity: 0.7;
        }
      }
    }

    .background {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;

      & > div {
        height: 100%;
        width: 100%;
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .icon {
      z-index: 10;
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .svg-image {
        justify-content: center;
        align-items: center;
        position: relative;
        display: flex;
        width: 22%;
        height: 22%;
        z-index: 10;

        & > div {
          height: 100%;
        }

        &.facebook,
        &.odnoklassniki {
          height: 20%;
        }

        &.youtube {
          height: 16%;
        }

        svg {
          width: initial;
          height: 100%;
          fill: $white;

          path {
            fill: $white;
          }
        }
      }

      .preset {
        transition: $transition;
        position: absolute;
        left: 0;
        top: 0;
        background: $black;
        opacity: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
      }
    }
  }
}
