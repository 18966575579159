@import '../../css/guideline.scss';

$bannerNYRightWidthDesktop: 439px;
$bannerNYRightWidthTablet: 82px;
$frameWidth: 359px;


body .mp-player.fixed.banner-ny {
  #mp {
    $paddingDesktop: #{$playerLeftPaddingRightDesktop} + #{$playerLeftPaddingLeftDesktop} +
      2px;
    $paddingTablet: #{$playerLeftPaddingRightTablet} + #{$playerLeftPaddingLeftTablet} +
      2px;
    $paddingMobile: #{$playerLeftPaddingRightMobile} + #{$playerLeftPaddingLeftMobile} +
      2px;
    $paddingSmallMobile: #{$playerLeftPaddingRightSmallMobile} + #{$playerLeftPaddingLeftSmallMobile} +
      2px;

    @include media(
        (
          width: (
            all:
              calc(
                100% - (#{$bannerNYRightWidthDesktop}) - (#{$playerLeftDesktop}) -
                  (#{$paddingDesktop})
              ),
            tablet:
              calc(
                100% - (#{$bannerNYRightWidthTablet}) - (#{$playerLeftTablet}) -
                  (#{$paddingTablet})
              ),
          ),
          max-width: (
            all:
              calc(
                100% - (#{$bannerNYRightWidthDesktop}) - (#{$playerLeftDesktop}) -
                  (#{$paddingDesktop})
              ),
            tablet:
              calc(
                100% - (#{$bannerNYRightWidthTablet}) - (#{$playerLeftTablet}) -
                  (#{$paddingTablet})
              ),
          ),
        )
      );
  }

  .mp-custom-right {
    z-index: 999;
    
    @include media(
      (
        width: (
          all: $bannerNYRightWidthDesktop,
          tablet: $bannerNYRightWidthTablet
        )
      )
    )
  }
}

.banner-ny-control {  
  @include media(
    (
      width: (
        all: $frameWidth,
        tablet: 0
      )
    )
  );

  .big-santa {
    width: $frameWidth;
    cursor: pointer;

    @include media(
      (
        display: (
          all: block,
          tablet: none
        )
      )
    );
  }
}

.mobile-santa {
  cursor: pointer;
  display: block;
  width: 100%;
}

.banner-ny-frame-container {
  position: fixed;
  z-index: 1031;

  @include media(
    (
      top: (
        all: 80px,
        mobile: 0
      ),
      right: (
        all: 80px,
        mobile: 0
      ),
      height: (
        all: 684px,
        mobile: 100vh
      ),
      width: (
        all: $frameWidth,
        mobile: 100%
      ),
      max-height: (
        all: calc(100vh - 80px),
        mobile: 100vh
      )
    )
  );

  .cross {
    position: absolute;
    left: 50%;
    margin-left: 140px;
    top: 15px;
    width: 25px;
    height: 25px;

    .line {
      background-color: $white;
    }
  }
}

.dark-container {
  @include media(
    (
      position: (
        all: fixed,
        mobile: static
      ),
      top: (
        all: 80px,
        mobile: 0
      ),
      left: (
        all: 0,
      ),
      height: (
        all: 100%,
      ),
      width: (
        all: 100%,
      ),
      background-color: (
        all: rgba(0, 0, 0, .8)
      )
    )
  );
}

