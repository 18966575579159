@import '../../css/guideline.scss';

body {
  .artists-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .placeholder {
      width: 100% !important;
      height: 100%;
      display: flex;
      min-height: 200px;
      align-items: center;
      justify-content: center;
    }

    .row {
      display: flex;

      @include media(
        (
          flex-direction: (
            all: row,
            mobile: column
          )
        )
      );

      @for $i from 1 to 4 {
        &[data-count='#{$i}'] {
          & > div,
          & > a {
            @include media(
              (
                width: (
                  all: 100% / $i,
                  tablet: 100%,
                  mobile: 100%
                )
              )
            );
          }
        }
      }
    }

    &.hide {
      display: none;
    }

    &.row {
      @include media(
        (
          flex-direction: (
            all: row
          )
        )
      );

      & > div,
      a {
        width: 33.33%;

        &:nth-child(3n + 3) {
          width: 33.34%;
        }
      }
    }

    &.column {
      @include media(
        (
          flex-direction: (
            all: column,
            tablet: row
          )
        )
      );

      & > div,
      & > a {
        @include media(
          (
            width: (
              all: 100%,
              tablet: 50%,
              mobile: 100%
            )
          )
        );
      }
    }
  }
}
