@import '../../../css/guideline.scss';

body {
  .static-wrap {
    .static-column {
      .static-teammates {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-wrap: wrap;

        margin-top: 0 !important;

        .static-teammate {
          display: flex;
          flex-direction: column;

          max-width: 200px;
          margin-top: 60px;
          margin-right: 60px;
          padding-bottom: 50px;

          border-bottom: 2px solid $black;

          .teammate-image {
            position: relative;

            overflow: hidden;

            min-width: 200px;
            height: 200px;

            transition: $transition;

            background-color: #ede9e6;

            img {
              width: 100%;
              height: 100%;

              transition: $transition;

              object-fit: cover;
            }

            .teammate-contact {
              position: absolute;
              right: 0;
              bottom: 0;

              display: flex;

              background-color: $black;

              svg {
                position: inherit;

                width: 40px;
              }
            }
          }

          .teammate-info {
            display: flex;
            flex-direction: column;

            a {
              margin-top: 10px;

              color: inherit;
            }

            strong {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
