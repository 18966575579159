@import '../../css/guideline.scss';

body {
  .no-script-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000;
    background: black;
    padding: 40px;
    box-sizing: border-box;

    .logotype {
      width: 120px;
      margin: 0 auto 60px;
    }

    .no-script {
      width: 90%;
      display: flex;
      max-width: 720px;
      flex-direction: column;

      .no-script {
        &__header {
          background: #ede9e6;
          box-sizing: border-box;
          padding: 20px 20px 60px;

          &-title {
            margin-bottom: 20px;
          }

          .typography {
            max-width: 560px;
          }
        }

        &__body {
          display: flex;
          background: #fff;

          .browser {
            width: 100%;
            display: flex;
            padding: 30px 0;
            align-items: center;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: center;

            a {
              width: 60%;
              max-width: 80px;
              margin-bottom: 20px;

              img {
                width: 100%;
              }
            }

            & + .browser {
              border-left: 1px solid #ede9e6;
            }
          }
        }
      }
    }
  }
}
