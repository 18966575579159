@import '../../css/guideline.scss';

$transition: all 750ms;

body {
  // .delete-acc-modal {
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 9999;
  //   left: 0;
  //   top: 0;
  //   .delete-acc-popup {

  //   }
  // }
  // @media screen and (max-width: 720px) {
  //   .user-component {
  //     opacity: 0;
  //   }
  // }
  .user-component {
    position: relative;

    display: flex;

    min-width: 20px;
    min-height: 20px;

    transition: $transition;

    .avatar {
      position: relative;
      z-index: 3;

      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      min-width: 100%;
      padding-top: 4px;

      text-transform: uppercase;

      color: $white;
      border-radius: 100%;
      background: #c4c4c4;

      line-height: 1;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }

    .logout {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: flex-end;

      box-sizing: border-box;
      width: 0;
      width: 100%;
      height: 100%;
      padding-right: 10px;

      cursor: pointer;
      transition: $transition;

      color: $darkGray;
      border-radius: 200px;
      background: #292929;

      p {
        padding: 0 10px;
        color: $darkGray;
        white-space: nowrap;
      }

      & > div {
        display: flex;
        align-items: center;
        flex-direction: row;

        width: initial !important;
        padding-right: 30px;
      }
    }

    &.login {
      &:hover {
        padding-right: 300px;
      }
    }

    @media screen and (max-width: 720px) {
      .hiddenw, .logoutline {
        display: none;
      }
      &.login {
        &:hover {
          padding-right: 100px;
        }
      }
    }
  }
}
