@import '../../css/guideline.scss';

body {
  .song-player {
    left: 0;
    position: absolute;
    width: 0;
    top: 0;
    right: 0;
    z-index: -1000;
    opacity: 0;
    pointer-events: none;
  }
}
