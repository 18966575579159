@import '../../css/guideline.scss';

body {
  .svg-symbol {
    &.reverse {
      transform: scaleX(-1);
    }

    svg {
      display: flex;
    }

    &[data-fill='white-red'] {
      svg {
        fill: $white;

        path {
          &:last-child {
            fill: $red;
          }
          &:first-child {
            fill: $white;
          }
        }

        &[data-type='track'] {
          path {
            &:last-child {
              fill: $white;
            }
            &:first-child {
              fill: $white;
            }
          }
        }
      }
    }

    &[data-fill='red-white'] {
      svg {
        fill: $red;

        path {
          &:last-child {
            fill: $white;
          }
          &:first-child {
            fill: $red;
          }
        }

        &[data-type='track'] {
          path {
            &:last-child {
              fill: $red;
            }
            &:first-child {
              fill: $red;
            }
          }
        }
      }
    }

    &[data-fill='custom'] {
      svg {
        fill: inherit !important;
      }
    }

    &[data-fill='default'] {
      svg {
        fill: $blue;

        path {
          &:last-child {
            fill: $blue;
          }
          &:first-child {
            fill: $red;
          }
        }
      }

      &[data-type='track'] {
        path {
          &:last-child {
            fill: $blue;
          }
          &:first-child {
            fill: $blue;
          }
        }
      }
    }

    &[data-fill='white'] {
      svg {
        fill: $white;

        path {
          &:last-child {
            fill: $black;
          }
          &:first-child {
            fill: $white;
          }
        }
      }

      &[data-type='back'] {
        svg {
          path {
            stroke: $white;
          }
        }
      }

      &[data-type='track'] {
        path {
          &:last-child {
            fill: $white;
          }
          &:first-child {
            fill: $white;
          }
        }
      }
    }

    &[data-fill='black'] {
      svg {
        fill: $black;

        path {
          &:last-child {
            fill: $white;
          }
          &:first-child {
            fill: $black;
          }
        }
      }

      &[data-type='track'] {
        path {
          &:last-child {
            fill: $black;
          }
          &:first-child {
            fill: $black;
          }
        }
      }

      &[data-type='back'] {
        svg {
          path {
            stroke: $black;
          }
        }
      }
    }
  }
}
