@import '../../css/guideline.scss';

$playContainerPercent: 60%;

body {
  .gray {
    .song {
      &:first-child {
        .information {
          border-top: $darkBorder;
        }
      }
      .information {
        border-bottom: $darkBorder;

        .like-container {
          .icons {
            .svg-image {
              svg {
                path {
                  stroke: $veryLightBlack;
                }
              }

              &:hover {
                svg {
                  path {
                    stroke: $black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .song {
    position: relative;

    display: flex;
    justify-content: space-between;

    .play-container {
      // background: $red;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      transition: $transition;

      opacity: 0;

      @include media(
        (
          width: (
            all: 100px,
            mobile: 80px,
          ),
          min-width: (
            all: 100px,
            mobile: 80px,
          ),
          height: (
            all: 100px,
            mobile: 80px,
          ),
        )
      );

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;

        width: $playContainerPercent;
        height: $playContainerPercent;
        margin: auto;

        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.8);

        .svg-image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          width: 40px; //100% = 60px
          height: 40px; //100% = 60px
          margin: auto;

          transition: $transition;

          opacity: 0;

          &.active {
            opacity: 1;
          }

          & > div {
            height: 100%;
          }

          svg {
            width: 100%;
            height: 100%;

            rect {
              fill: $white;
            }

            path {
              fill: $white;
            }
          }
        }
      }

      &:hover {
        opacity: 1 !important;
      }
    }

    &:hover {
      .play-container {
        opacity: 0.5;
      }
    }

    &.played {
      .play-container {
        opacity: 1;
      }
      &:hover {
        .play-container {
          opacity: 1;
        }
      }
    }

    .preview {
      user-select: none;

      object-fit: cover;
      @include media(
        (
          width: (
            all: 100px,
            mobile: 80px,
          ),
          min-width: (
            all: 100px,
            mobile: 80px,
          ),
          height: (
            all: 100px,
            mobile: 80px,
          ),
        )
      );

      & > .svg-image > svg {
        width: 100%;
        height: 100%;
      }
    }

    &:first-child {
      .information {
        border-top: $lightBorder;
      }
    }

    .information {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      border-bottom: $lightBorder;

      @include media(
        (
          margin-left: (
            all: 40px,
            mobile: 10px,
          ),
        )
      );

      .like-container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: flex-end;

        &.liked {
          .icons {
            .svg-image.like {
              svg {
                path {
                  fill: $red;
                  stroke: $red;
                }
              }
            }
          }
        }

        &.disliked {
          .icons {
            .svg-image.dislike {
              svg {
                path {
                  fill: $red;
                  stroke: $red;
                }
              }
            }
          }
        }

        .icons {
          display: flex;
          align-items: center;

          & > div {
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            margin: 0 10px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          .svg-image {
            width: 40px;
            height: 40px;

            &.dislike {
              transform: rotate(180deg);
            }

            svg {
              path {
                transition: $transition;

                fill: transparent;
                stroke: $darkGray;
              }
            }

            &:hover {
              svg {
                path {
                  stroke: $white;
                }
              }
            }

            &:first-child {
              margin-top: 0.2vw;
            }
          }
        }

        .quantity {
          user-select: none;

          color: $veryLightBlack;
        }
      }

      .author {
        @include media(
          (
            margin-bottom: (
              all: 10px,
              mobile: 0,
            ),
          )
        );
        a {
          color: inherit;
        }
      }

      .name {
        color: $veryLightBlack;
      }
    }
  }
}
