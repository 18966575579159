@import '../../css/guideline.scss';

body {
  .cities {
    left: 0;
    width: 100vw;
    display: flex;
    color: $white;
    position: fixed;
    background: $black;
    z-index: $zIndexCities;
    flex-direction: column;
    transition: $menuTransition;

    top: -100vh;
    top: calc(var(--vh, 1vh) * -100);

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    .title-block {
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 40px 70px;

        @include media(
          (
            padding: (
              all: 25px 40px 70px,
              mobile: 20px 20px 60px
            ),
            white-space: (
              all: pre,
              mobile: none
            )
          )
        );

        .title-container {
          display: flex;
          align-items: center;

          @include media(
            (
              white-space: (
                all: pre,
                mobile: none
              )
            )
          );

          .logotype-component {
            width: 120px;
            min-width: 120px;

            @include media(
              (
                display: (
                  mobile: none
                )
              )
            );

            svg {
              opacity: 1;
            }
          }

          .frequency {
            width: auto;
            margin-left: 20px;
            @include media(
              (
                display: (
                  tablet: none
                )
              )
            );
          }

          .title {
            @include media(
              (
                margin-left: (
                  all: 52%,
                  laptop: 19%,
                  tablet: 25px,
                  mobile: 0px
                )
              )
            );
          }
        }

        .cross {
          width: 26px;
          height: 26px;
        }
      }
    }

    .cities-block {
      height: 100%;
      max-height: 100%;
      overflow-y: auto;

      .cities-block-content {
        display: block;

        @include media(
          (
            width: (
              all: calc(100% - 80px),
              mobile: calc(100% - 40px)
            ),
            padding: (
              all: 0 calc(2 * 20px) calc(2 * 20px),
              mobile: 0 calc(1 * 20px) calc(2 * 20px)
            ),
            column-count: (
              all: 4,
              laptop: 3,
              tablet: 2,
              mobile: 1
            ),
            column-gap: (
              all: 80px,
              mobile: 0
            )
          )
        );

        & > div {
          width: 100%;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          & > div {
            & > div {
              &:first-child {
                .cities-group {
                  margin-left: 0;
                }
              }
              &:last-child {
                .cities-group {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .city-group-line {
          height: 1px;
          background: $veryLightBlack;
          margin: 40px 0 20px;
        }

        .city-container {
          cursor: pointer;
          margin-bottom: 20px;
          display: inline-block;
          flex-direction: column;
          transition: $transition;

          line-height: 1;

          &:hover {
            color: $red;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.active {
            color: $red;
          }

          .city {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .city-frequency {
              white-space: pre;
              padding-top: 4px;
            }

            .city-name-container {
              display: flex;
              padding-right: 10px;
              align-items: center;

              .city-name {
                padding-top: 4px;
              }

              .svg-symbol {
                width: 20px;
                height: 100%;
                min-width: 20px;
                margin-right: calc(0.5 * 20px);

                svg {
                  width: 100%;
                  height: auto;
                }

                path:last-child {
                  fill: $black;
                }
              }
            }
          }
        }
      }
    }

    &.visible {
      top: 0;
    }
  }
}
