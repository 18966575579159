@import '../../../css/guideline.scss';

body {
  .static-wrap .static-column .static-contacts > div {
    @include media(
      (
        min-width: (
          all: 300px,
          tablet: 0,
        ),
      )
    );

    a {
      margin-top: 12px;
      word-break: break-all;
    }
  }

  .static-wrap {
    .static-column {
      .static-download {
        display: flex;
        justify-content: space-between;

        padding: 40px;

        background-color: #ede9e6;

        @include media(
          (
            flex-direction: (
              tablet: column,
            ),
            align-items: (
              all: center,
              tablet: flex-start,
            ),
          )
        );

        svg {
          width: 30px;
          height: 43px;
          path,
          line {
            stroke: $black;
          }
        }

        .kit-download {
          display: flex;
          align-items: center;

          @include media(
            (
              margin-top: (
                tablet: 40px,
              ),
            )
          );

          .svg-image {
            margin-left: 20px;
          }
        }

        span {
          max-width: 380px;
        }
      }
    }
  }
}
