@import '../../css/guideline.scss';

.article {
    padding: 40px;
    border-bottom: 1px solid #959CA4;
    .title {
        color: black;
        @include media(
            (
                margin-bottom: (
                    all: 120px,
                    mobile: 50px,
                ),
                font-size: (
                    all: 42px,
                    mobile: 24px,
                )
            )
        )
    }
    .date {
        color: black;
    }
    .time {
        color: black;
    }
}