@import "../../css/guideline.scss";

// guideline
// all margin - bottom;

body {
  .content-post {
    .html-container {
      .slick-slider {
        position: relative;

        width: 50%;
        margin: 0 auto;

        @include media(
          (
            width: (
              all: 50%,
              tablet: 75%,
              mobile: 100%
            )
          )
        );

        .slick-list {
          transition: all 0.25s ease 0s;
        }

        .slick-arrow {
          z-index: 10;

          display: flex !important;
          align-items: center;
          justify-content: center;

          width: 10%;
          min-width: 24px;
          max-width: 36px;

          & > div {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 0;
            padding-bottom: 100%;

            transition: $transition;

            opacity: 0.5;
            border-radius: 100%;
            background-color: black;

            &:hover {
              opacity: 1;
            }
          }

          .svg-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: flex;

            width: 50%;
            height: 50%;
            margin: auto;

            svg {
              width: 100%;
              height: 100%;

              path {
                stroke: white;
              }
            }
          }

          &:before,
          &:after {
            display: none;
          }

          &.slick-prev {
            left: 10px;

            .svg-image {
              transform: scaleX(-1);
            }
          }

          &.slick-next {
            right: 10px;
          }
        }

        .slick-dots {
          position: absolute;
          bottom: 20px;

          display: block;

          width: 100%;
          margin: 0;
          padding: 0;

          list-style: none;

          text-align: center;

          li {
            width: 50px;
            height: 4px;

            &.slick-active {
              button {
                opacity: 1;
              }
            }

            button {
              width: 100%;
              height: 100%;
              padding: 0;

              transition: $transition;

              opacity: 0.3;
              background: $white;

              &::before {
                display: none;
              }
            }
          }
        }
      }

      .share-container {
        .share-block {
          @include media(
            (
              margin-top: (
                all: 40px,
                // mobile: 20px
              )
            )
          );

          .share-title {
            margin-bottom: 20px;
          }
        }
      }

      p,
      span,
      b,
      i,
      h4,
      h1,
      h2,
      h3,
      hr,
      ul,
      em,
      .kg-gallery-card,
      blockquote {
        &:not(.slick-dots) {
          max-width: 720px;
        }
      }

      em {
        font-style: italic;
      }

      figure {
        &:last-child,
        &:last-of-type {
          margin-bottom: 0;
        }

        img {
          width: 100%;
          height: auto;
          margin-bottom: 20px;

          &:last-child,
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      blockquote {
        border-left: 4px solid $black;

        @include media(
          (
            padding-left: (
              all: 40px,
              mobile: 20px
            )
          )
        );

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2,
      h3 {
        font-size: 34px;
        font-weight: 500;
        line-height: 34px;

        @include media(
          (
            margin-bottom: (
              all: 40px
            )
          )
        );

        &:last-child {
          margin-bottom: 0;
        }
      }

      h1,
      blockquote {
        margin-bottom: 40px;

        text-transform: uppercase;

        font-weight: 500;

        @include media(
          (
            font-size: (
              all: 40px,
              mobile: 34px
            ),
            line-height: (
              all: 40px,
              mobile: 34px
            ),
            letter-spacing: (
              all: -2px
            )
          )
        );

        &:last-child {
          margin-bottom: 0;
        }
      }

      blockquote {
        @include media(
          (
            font-size: (
              all: 34px,
              mobile: 24px
            ),
            line-height: (
              all: 40px,
              mobile: 30px
            )
          )
        );
      }

      hr {
        height: 2px;

        opacity: 0.8;
        border: none;
        background: $black;

        @include media(
          (
            margin: (
              all: 0 0 40px,
              // mobile: 0 0 20px
            )
          )
        );

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        transition: $transition;

        color: #10008c;

        &:hover {
          color: $vk;
        }
      }

      p,
      span,
      b,
      i,
      h4,
      em,
      ul {
        display: block;

        @include media(
          (
            margin-bottom: (
              all: 40px,
              // mobile: 20px
            )
          )
        );
        @include media(
          (
            font-size: (
              all: 24px,
              mobile: 20px
            ),
            line-height: (
              all: 30px,
              mobile: 22px
            ),
            letter-spacing: (
              all: -0.5px,
              mobile: 0.4px
            )
          )
        );

        li {
          &:last-child {
            margin-bottom: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .iframe {
        position: relative;

        width: 100%;
        height: 0;
        padding-top: 56.25%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          border: 0;
        }
      }

      .kg-card {
        overflow: hidden;

        width: 100%;

        .kg-gallery-row {
          @include media(
            (
              margin-bottom: (
                all: 40px,
                // mobile: 20px
              )
            )
          );

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.kg-image-card,
        &.kg-gallery-row,
        &.kg-gallery-card,
        &.kg-embed-card {
          @include media(
            (
              margin-bottom: (
                all: 60px,
                // mobile: 20px
              )
            )
          );

          &:last-child {
            margin-bottom: 0;
          }
        }

        figcaption {
          margin-top: 20px;
          color: $veryLightBlack;
          letter-spacing : 1px;
          font-size: 16px;
          line-height: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .kg-gallery-container {
        .kg-gallery-row {
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }

          .kg-gallery-arrow {
            position: absolute;
            z-index: 15;
            top: 0;

            width: 100px;
            height: 100%;

            &:hover {
              background: $black;
            }

            &.right {
              right: 0;
            }

            &.left {
              left: 0;
            }
          }

          .owl-nav {
            .owl-prev,
            .owl-next {
              position: absolute;

              width: 60px;
              height: 60px;

              color: $white;
              outline: none;
              background: $black;
            }

            .owl-prev {
              top: 0;
              bottom: 0;
              left: 0;
            }
            .owl-next {
              top: 0;
              right: 0;
              bottom: 0;
            }
          }

          .owl-dots {
            position: absolute;
            z-index: 20;
            right: 0;
            bottom: 30px;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            .owl-dot {
              position: relative;

              display: flex;
              align-items: center;
              justify-content: center;

              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
              margin: 0 5px;

              cursor: pointer;
              transition: $transition;

              opacity: 0.5;
              border-radius: 20px;
              outline: none;
              background: $darkGray;

              &.active {
                opacity: 1;
              }

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .kg-gallery-image {
            width: 100%;
            height: 100%;

            img {
              display: flex;

              width: 100%;
              height: 100%;

              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
