@import '../../css/guideline.scss';

$liveSize: 430px;

$controlColor: $white;
$desktopControlSize: 60px;
$mobileControlSize: 48px;

$modePadding: 6px 20px;
$mobileModePadding: 3px 12px;
$modePlayerPadding: 1px 3px;

$topDesktop: calc(
  100vw * 0.4875 - 40px - #{$liveSize} * 0.5625 + #{$playerHeight}
);
$topTablet: calc(
  100vw * 0.4875 - 40px - #{$liveSize} * 0.5625 * 0.7 + #{$playerHeight}
);

body.live-fullscreen {
  .live-player.fullscreen {
    position: fixed !important;
    z-index: 10000 !important;
    top: 0 !important;
    left: 0 !important;

    width: 100% !important;
    height: 100% !important;
    padding-top: 0 !important;
  }
}
body {
  .live-player {
    &:not(.is-broadcast) {
      .live-control-block {
        .live-control {
          &.mode {
            padding: $mobileModePadding;

            font-size: 16px !important;
          }
        }
      }
    }

    .live-control-block {
      position: absolute;
      z-index: 15;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      .live-control {
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        transition: $transition;

        &.mode {
          z-index: 20;
          top: 0;
          left: 0;

          width: initial;
          height: initial;
          padding: $modePadding;

          user-select: none;
          text-transform: uppercase;

          color: $white;
          background: $red;

          div {
            padding-top: 4px;
          }
        }

        &.play {
          z-index: 10;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          width: 100%;
          height: 100%;

          transition: all 0.5s;

          background: transparent;

          svg {
            width: 100%;

            transform: scale(1);

            opacity: 0.7;
          }

          &.hide {
            svg {
              width: 100%;

              transform: scale(1.5);

              opacity: 0;
            }
          }

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 16%;
            height: 16%;
          }
        }

        &.muted {
          width: $desktopControlSize;
          height: $desktopControlSize;

          background: $black;

          svg {
            width: 100%;

            opacity: 0.7;
          }

          &:hover {
            background: $lightBlack;

            svg {
              opacity: 1;
            }
          }

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;

            &.muted {
              width: 54%;
              height: 54%;

              fill: $white;
            }

            &.volume {
              width: 28%;
              height: 28%;
            }
          }
        }

        &.fullscreen {
          position: relative;

          background: $black;

          @include media(
            (
              width: (
                all: $desktopControlSize,
                tablet: $mobileControlSize,
              ),
              height: (
                all: $desktopControlSize,
                tablet: $mobileControlSize,
              ),
            )
          );

          &:hover {
            background: $lightBlack;
            svg {
              opacity: 1;
            }
          }

          .svg-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 68%;
            height: 68%;
            margin: auto;

            transition: $transition;
            transform: scale(0);

            &.active {
              transform: scale(1);
            }

            & > div {
              width: 100%;
              height: 100%;

              svg {
                width: 100%;
                height: 100%;

                opacity: 0.7;
              }
            }
          }
        }
      }

      .live-control-piece {
        position: absolute;
        z-index: 20;
        right: 0;
        bottom: 0;

        display: flex;

        .live-control {
          position: relative;

          align-items: center;
        }
      }
    }
  }
}

// позиционирование на странице

body {
  .live-player {
    iframe,
    .player {
      position: absolute;
      top: 0;
      left: 0;

      width: 100% !important;
      height: 100% !important;

      border: 0;

      video {
        object-fit: cover;
      }
    }
  }

  .live-player {
    position: absolute;
    z-index: $zIndexLive;
    right: 40px;

    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    background: $black;

    @include media(
      (
        width: (
          all: $liveSize,
          tablet: calc(#{$liveSize} * 0.7),
        ),
        height: (
          all: calc(#{$liveSize} * 0.5625),
          tablet: calc(#{$liveSize} * 0.5625 * 0.7),
        ),
        top: (
          all: $topDesktop,
          tablet: $topTablet,
        ),
        display: (
          mobile: none,
        ),
      )
    );

    &.is-second-page {
      display: none;
    }

    &.is-fixed {
      position: fixed;
      z-index: $zIndexLive + 1;
      top: calc(#{$playerHeight} + 40px);
      right: 40px;
      bottom: initial;

      display: flex;

      transition: opacity 0ms;

      opacity: 0;

      &.is-fixed-bottom {
        right: 40px;
        bottom: 40px;
      }

      &.is-fixed-visible {
        transition: opacity 500ms;

        opacity: 1;
      }
    }

    &.is-broadcast {
      position: relative;
      top: 0;
      right: 0;
      left: 0;

      display: flex;

      width: 100%;
      height: 0;
      padding-top: 56.25%;

      background: #000;

      .loader {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        margin: 0 auto;
      }
    }
  }
}
