@import '../../css/guideline.scss';

body {
  .global-loader {
    position: fixed;
    z-index: $zIndexLoader;
    top: 0;
    left: 0;

    display: flex;

    width: 100vw;
    height: 100%;

    transition: opacity 250ms;

    opacity: 0;
    background: $black;

    &.visible {
      opacity: 1;
    }

    .progress {
      height: 10px;

      background: $white;
    }
  }
}
