@import '../../css/guideline.scss';

$fontFamilySecond: 'Roboto', sans-serif;
$fontFamily: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

.typography {
  &_font {
    &_main {
      letter-spacing: 0.4px;
      font-family: $fontFamily;
    }
    &_second {
      letter-spacing: 0.4px;
      font-family: $fontFamilySecond;
    }
  }

  &_clickable {
    cursor: pointer;
  }

  &_text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &_button-type {
    margin: 0;
    padding: 0;

    border: none;
    outline: none;
  }

  &_mark {
    &_bold {
      font-weight: 700;
    }

    &_medium {
      font-weight: 500;
    }

    &_regular {
      font-weight: 400;
    }

    &_light {
      font-weight: 300;
    }
  }

  &_color {
    &_white {
      color: $white;
    }
    &_black {
      color: $black;
    }
    &_dark-gray {
      color: $darkGray;
    }
    &_inherit {
      color: inherit;
    }
  }

  &_uppercase {
    text-transform: uppercase;
  }

  &.typography_type_title {
    text-transform: uppercase;
    font-weight: 500;

    &.typography_size {
      &_xl {
        @include media(
          (
            line-height: (
              ultra: 0.9,
            ),
            font-size: (
              ultra: 3.14vw,
            ),
            letter-spacing: (
              ultra: -0.15vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 55px,
              tablet: 40px,
              mobile: 34px,
              verySmallMobile: 28px,
            ),
            font-size: (
              all: 60px,
              tablet: 40px,
              mobile: 34px,
              verySmallMobile: 28px,
            ),
            letter-spacing: (
              all: -3px,
              mobile: -2px,
              verySmallMobile: -1px,
            ),
          )
        );
      }

      &_l {
        @include media(
          (
            line-height: (
              ultra: 0.9,
            ),
            font-size: (
              ultra: 2.6vw,
            ),
            letter-spacing: (
              ultra: -0.16vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 45px,
              tablet: 1,
              mobile: 1,
            ),
            font-size: (
              all: 50px,
              tablet: 40px,
              mobile: 34px,
            ),
            letter-spacing: (
              all: -3px,
              tablet: -3px,
              mobile: -2px,
            ),
          )
        );
      }

      &_m {
        @include media(
          (
            line-height: (
              ultra: 1,
            ),
            font-size: (
              ultra: 2.085vw,
            ),
            letter-spacing: (
              ultra: -0.1vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 40px,
              laptop: 34px,
              mobile: 24px,
            ),
            font-size: (
              all: 40px,
              laptop: 34px,
              mobile: 24px,
            ),
            letter-spacing: (
              all: -2px,
              laptop: -2px,
              mobile: -0.5px,
            ),
          )
        );
      }

      &_s {
        @include media(
          (
            line-height: (
              ultra: 1,
            ),
            font-size: (
              ultra: 1.25vw,
            ),
            letter-spacing: (
              ultra: -0.1vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 24px,
              mobile: 24px,
              verySmallMobile: 18px,
            ),
            font-size: (
              all: 24px,
              mobile: 24px,
              verySmallMobile: 18px,
            ),
            letter-spacing: (
              all: -2px,
              mobile: -0.5px,
              verySmallMobile: 0,
            ),
          )
        );
      }
    }
  }

  &.typography_type_subtitle {
    &.typography_size {
      &_m {
        font-weight: 500;

        @include media(
          (
            line-height: (
              ultra: 1,
            ),
            font-size: (
              ultra: 1.76vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 34px,
              mobile: 24px,
            ),
            font-size: (
              all: 34px,
              mobile: 24px,
            ),
          )
        );
      }

      &_s {
        @include media(
          (
            line-height: (
              ultra: 0.9,
            ),
            font-size: (
              ultra: 1.25vw,
            ),
            letter-spacing: (
              ultra: -0.026vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 30px,
              mobile: 1.1,
            ),
            font-size: (
              all: 24px,
              mobile: 20px,
            ),
            letter-spacing: (
              all: -0.5px,
            ),
          )
        );
      }
    }
  }

  &.typography_type_text {
    &.typography_size {
      &_xl {
        @include media(
          (
            line-height: (
              ultra: 1,
            ),
            font-size: (
              ultra: 1.25vw,
            ),
            letter-spacing: (
              ultra: -0.026vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 24px,
              tablet: 20px,
            ),
            font-size: (
              all: 24px,
              tablet: 16px,
            ),
            letter-spacing: (
              all: -0.5px,
              tablet: -0.5px,
            ),
          )
        );
      }

      &_l {
        @include media(
          (
            line-height: (
              ultra: 1,
            ),
            font-size: (
              ultra: 1.04vw,
            ),
            letter-spacing: (
              ultra: -0.026vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 20px,
              mobile: 16px,
            ),
            font-size: (
              all: 20px,
              mobile: 16px,
            ),
            letter-spacing: (
              all: 0.5px,
              mobile: 0.5px,
            ),
          )
        );
      }

      &_m {
        @include media(
          (
            line-height: (
              ultra: 1,
            ),
            font-size: (
              ultra: 0.83vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 20px,
              mobile: 16px,
            ),
            font-size: (
              all: 16px,
              mobile: 14px,
            ),
          )
        );
      }

      &_s {
        @include media(
          (
            line-height: (
              ultra: 1,
            ),
            font-size: (
              ultra: 0.625vw,
            ),
          ),
          false,
          min
        );

        @include media(
          (
            line-height: (
              all: 16px,
            ),
            font-size: (
              all: 12px,
            ),
          )
        );
      }
    }
  }
}
