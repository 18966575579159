@import '../../../css/guideline.scss';

body {
  .static-container {
    .static-expand {
      margin-top: 60px;
      padding: 40px 0;

      border-top: 2px solid $black;

      .wrapper {
        padding: 0;
      }

      svg {
        width: 40px;
        height: 40px;
      }

      &.expanded {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .expanded-list {
      list-style: none !important;
      counter-reset: list;

      h2 {
        &:before {
          content: counter(list) '\00a0—\00a0';
          counter-increment: list;
        }
      }

      li {
        ol {
          padding-top: 1.5em;
        }

        p {
          margin-top: 1.5em;
        }
      }
    }
  }
}
