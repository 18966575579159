@import '../../css/guideline.scss';

.adfox-static-banner-container {
  margin-top: $playerHeight;

  &__banner {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100% !important;
    height: auto !important;

    &_mobile {
      font-size: 0;
    }

    > * {
      display: block;
    }

    &__news-footer {
      margin-top: 30px;
    }

    &__header {
      background-color: $black;
    }

    &__after-song {
      background-color: $black;
    }

    &__mobile-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
    }
  }
}
