@import '../../css/guideline.scss';

body {
  .share {
    display: flex;

    .SocialMediaShareButton {
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex;
      outline: none;
      border: none;

      .svg-image {
        width: 100%;
        height: 100%;
        fill: $white;

        div,
        svg {
          width: 100%;
          height: 100%;

          path {
            transition: $transition;
          }
        }
      }

      &.original {
        border-radius: 2px;

        .svg-image {
          width: 50%;
          height: 50%;
        }
      }

      &.dark {
        .svg-image {
          svg {
            &:hover {
              path {
                fill: $black;
              }
            }
            path {
              fill: $darkGray;
            }
          }
        }
      }

      &.light {
        svg {
          &:hover {
            path {
              fill: $white;
            }
          }
          path {
            fill: $darkGray;
          }
        }
      }

      &.white {
        svg {
          &:hover {
            path {
              fill: $white;
            }
          }
          path {
            fill: $white;
          }
        }
      }

      &.black {
        svg {
          &:hover {
            path {
              fill: $black;
            }
          }
          path {
            fill: $black;
          }
        }
      }

      & + .SocialMediaShareButton {
        margin-left: 20px;
      }
    }

    .SocialMediaShareButton {
      &--twitter {
        &.original {
          background: $twitter;
        }
      }
      &--vk {
        &.original {
          background: $vk;
        }
      }
      &--facebook {
        &.original {
          background: $fb;
        }
      }
      &--ok {
        &.original {
          background: $ok;
        }
      }
    }
  }
}
