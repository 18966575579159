@import '../../css/guideline.scss';

.show-body {
  display: flex;
  color: $white;
  flex-direction: column;
  background: $lightBlack;

  .loader {
    margin: 80px auto 40px;
  }

  .show-body-grid {
    display: flex;
    flex-direction: row;

    & > div {
      width: 100%;
      background: transparent;

      &.poster-show-zone {
        &:hover {
          .poster-container {
            opacity: 1;
          }
        }
      }

      .show-head {
        background: transparent;
        padding-left: 40px;

        .poster-container {
          margin: 0;
          padding: 0;
          opacity: 0;
          transition: $transition;
        }
      }
    }
  }
}
