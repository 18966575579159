@import '../../css/guideline.scss';

body {
  .tile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;

    .tile-flex {
      display: flex;
      flex-wrap: wrap;

      width: 100%;

      & > div {
        display: flex;
        flex-wrap: wrap;

        width: 50%;
      }

      .column-2 {
        display: flex;
        flex-wrap: wrap;

        @include media(
          (
            width: (
              all: 66.67%,
              tablet: 100%
            )
          )
        );

        & > div {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .column-1 {
        display: flex;
        flex-wrap: wrap;

        @include media(
          (
            width: (
              all: 33.33%,
              tablet: 100%
            )
          )
        );

        & > div {
          display: flex;
          flex-wrap: wrap;

          @include media(
            (
              width: (
                tablet: 50%
              )
            )
          );
        }
      }
    }

    &.type-3 {
      .vertical-news a {
        @include media(
          (
            height: (
              tablet: 100%
            )
          )
        );
      }

      .news-card {
        &[column='1'] {
          @include media(
            (
              width: (
                all: 33.33%,
                tablet: 100%,
                mobile: 100%
              )
            )
          );
        }

        &:nth-child(3) {
          @include media(
            (
              width: (
                all: 33.34%,
                tablet: 100%,
                mobile: 100%
              )
            )
          );
        }
      }
    }

    &.type-4 {
      .vertical-news a {
        @include media(
          (
            height: (
              tablet: 100%
            )
          )
        );
      }

      .column-2 {
        .news-card {
          &[column='2'] {
            @include media(
              (
                width: (
                  all: 100%,
                  tablet: 100%
                )
              )
            );
          }
          &[column='1'] {
            @include media(
              (
                width: (
                  all: 50%,
                  tablet: 100%
                )
              )
            );
          }
        }
      }

      .column-1 {
        .news-card {
          &[column='1'] {
            @include media(
              (
                width: (
                  all: 50%,
                  tablet: 100%
                )
              )
            );
          }

          &[column='-1'] {
            @include media(
              (
                width: (
                  all: 100%,
                  tablet: 100%
                )
              )
            );
          }
        }
      }
    }

    &.type-2 {
      &.reverse {
        .news-card {
          &[column='1'] {
            flex-direction: row;

            @include media(
              (
                width: (
                  all: 33.34%,
                  tablet: 50%,
                  mobile: 100%
                )
              )
            );
          }
          &[column='2'] {
            flex-direction: row;

            @include media(
              (
                width: (
                  all: 66.66%,
                  tablet: 50%,
                  mobile: 100%
                )
              )
            );
          }
        }
      }

      .news-card {
        &[column='1'] {
          flex-direction: row;

          @include media(
            (
              width: (
                all: 33.33%,
                tablet: 50%,
                mobile: 100%
              )
            )
          );
        }
        &[column='2'] {
          flex-direction: row;

          @include media(
            (
              width: (
                all: 66.67%,
                tablet: 50%,
                mobile: 100%
              )
            )
          );
        }
      }
    }
  }
}
