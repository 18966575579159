@import '../../css/guideline.scss';

body {
  .show-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .placeholder {
      width: 100% !important;
      height: 100%;
      display: flex;
      min-height: 200px;
      align-items: center;
      justify-content: center;
    }

    &.hide {
      display: none;
    }

    &.row {
      flex-direction: row;

      & > div,
      a {
        width: 33.33%;

        &:nth-child(3n + 1) {
          width: 33.34%;
        }
      }
    }

    &.column {
      flex-direction: column;

      & > div,
      a {
        width: 100%;
      }
    }
  }
}
