@import '../../css/guideline.scss';

body {
  .download-buttons {
    display: flex;

    @include media(
      (
        flex-direction: (
          all: row,
        ),
        margin-left: (
          all: 40px,
          tablet: 0,
        ),
        margin-top: (
          all: 0,
          tablet: 40px,
          mobile: 20px,
        ),
        width: (
          all: initial,
          mobile: 100%,
        ),
        justify-content: (
          all: center,
          mobile: flex-start,
        ),
      )
    );

    a {
      display: block;

      height: 40px;

      transition: $transition;

      opacity: 0.8;

      &.google {
        max-width: 135px;

        @include media(
          (
            width: (
              all: 135px,
              mobile: 100%,
            ),
          )
        );
      }

      &.apple {
        max-width: 120px;

        @include media(
          (
            width: (
              all: 120px,
              mobile: 100%,
            ),
          )
        );
      }

      & + a {
        margin-left: 20px;
      }

      &:hover {
        opacity: 1;
      }

      .svg-image {
        svg {
          width: 100%;

          transition: $transition;

          fill: $darkGray;
        }
      }
    }
  }
}
