@import '../../css/guideline.scss';

$size: 20px;

body {
  .checkbox-item {
    display: flex;
    cursor: pointer;
    position: relative;
    margin-bottom: 20px;

    &.black {
      .checkbox {
        border: 2px solid $white;
      }

      input {
        &:checked ~ .checkbox {
          background-color: $white;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .checkbox {
      width: $size;
      height: $size;
      margin-right: 10px;
      align-items: center;
      box-sizing: border-box;
      border: 2px solid $black;
      background-color: transparent;
    }

    .label {
      @include media(
        (
          line-height: 28px,
          margin-top: (
            all: -2px,
            tablet: 0,
          ),
        )
      );
    }

    input {
      top: 0;
      left: 0;
      margin: 0;
      opacity: 0;
      width: $size;
      height: $size;
      border: none;
      user-select: none;
      position: absolute;
      pointer-events: none;
      box-sizing: border-box;

      &:checked ~ .checkbox {
        background-color: $black;
      }
    }
  }
}
