@import '../../css/guideline.scss';

.adfox-overlay-banner-modal > * {
  box-sizing: border-box;
}

.adfox-overlay-banner-modal {
  top: 0;
  left: 0;
  z-index: 1031;
  position: fixed;

  width: 100%;
  height: 100%;
  background: linear-gradient(#000000, transparent);

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  pointer-events: none;

  &_visible {
    opacity: 1;
    pointer-events: all;
  }

  &__close {
    top: 0;
    right: 20px;
    position: absolute;

    border-radius: 16px;
    cursor: pointer;

    color: black;
    background-color: white;

    width: 32px;
    height: 32px;
    font-size: 32px;
    line-height: 32px;
    font-family: 'Roboto';
  }

  &__timer {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    color: white;
    font-size: 16px;
    text-align: center;
    padding-right: 26px;
    height: 36px;

    &-counter {
      font-size: 24px;
    }
  }

  &__banner {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: auto !important;

    @include media(
      (
        padding-top: (
          all: 56px,
          mobile: 0
        )
      )
    );
    
    > * {
      display: block;
    }
  }
}
