@import '../../css/guideline.scss';

body {
  .frequency {
    display: inline-flex;
    justify-content: space-between;

    cursor: pointer;

    color: $white;

    @include media(
      (
        max-width: (
          all: 400px,
          mobile: none
        ),
        width: (
          mobile: 100%
        )
      )
    );

    &.player {
      display: grid;
      flex-direction: column;
      justify-content: center;

      width: 100%;
      max-width: none;

      .city {
        margin-right: 0;
      }

      .value {
        margin-top: -4px;
      }
    }

    .svg-image {
      width: 20px;
      height: 20px;
    }

    .city {
      overflow: hidden;

      margin-right: 20px;

      white-space: nowrap;
      text-overflow: ellipsis;

      color: $white;
    }

    .value {
      display: flex;
      align-items: center;

      white-space: pre;

      color: $white;

      .svg-image {
        &.active {
          svg {
            transform: rotate(180deg) translateY(5px);
          }
        }

        svg {
          width: 20px;
          padding-bottom: 4px;

          transition: $transition;
          transform: rotate(0deg) translateY(-2px);
        }
      }
    }
  }
}
