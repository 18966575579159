@import '../../css/guideline.scss';

body {
  .mp-player.fixed {
    .mp-player-button {
      @include media(
        (
          min-width: (
            all: $playerHeight,
            smallMobile: 100vw/4,
          ),
          width: (
            all: $playerHeight,
            smallMobile: 100vw/4,
          ),
          height: (
            all: $playerHeight,
          ),
          min-height: (
            all: $playerHeight,
          ),
        )
      );
    }

    .mp-custom-left {
      border-right: $lightBorder;

      @include media(
        (
          width: (
            all: $playerLeftDesktop,
            tablet: $playerLeftTablet,
            mobile: $playerLeftMobile,
            smallMobile: $playerLeftSmallMobile,
          ),
          padding-left: (
            all: $playerLeftPaddingLeftDesktop,
            tablet: $playerLeftPaddingLeftTablet,
            mobile: $playerLeftPaddingLeftMobile,
            smallMobile: $playerLeftPaddingLeftSmallMobile,
          ),
          padding-right: (
            all: $playerLeftPaddingRightDesktop,
            tablet: $playerLeftPaddingRightTablet,
            mobile: $playerLeftPaddingRightMobile,
            smallMobile: $playerLeftPaddingRightSmallMobile,
          ),
          align-items: (
            smallMobile: center,
          ),
          justify-content: (
            smallMobile: center,
          ),
        )
      );

      .logotype-component {
        @include media(
          (
            width: (
              all: 120px,
              tablet: 100px,
              mobile: $playerIconHeight,
            ),
            min-width: (
              all: 120px,
              tablet: 100px,
              mobile: $playerIconHeight,
            ),
            max-width: (
              all: 120px,
              tablet: 100px,
              mobile: $playerIconHeight,
            ),
            height: (
              tablet: 25px,
              mobile: $playerIconHeight,
            ),
            margin-right: (
              all: 20px,
              tablet: 0,
              mobile: 0,
            ),
          )
        );
        & > div {
          display: grid;
        }
      }
    }

    .mp-custom-right {
      @include media(
        (
          width: (
            all: $playerRightDesktop,
            tablet: $playerRightTablet,
            mobile: $playerRightMobile,
            smallMobile: $playerRightSmallMobile,
          ),
          z-index: (
            smallMobile: 152,
          ),
        )
      );

      .live-control {
        transition: all 0ms !important;

        @include media(
          (
            opacity: (
              smallMobile: 0,
            ),
          )
        );
      }

      .search {
        @include media(
          (
            border-width: (
              tablet: 0,
            ),
            width: (
              tablet: 0,
            ),
            max-width: (
              tablet: 0,
            ),
            min-width: (
              tablet: 0,
            ),
            overflow: (
              tablet: hidden,
            ),
          )
        );
      }
    }

    #mp {
      $paddingDesktop: #{$playerLeftPaddingRightDesktop} + #{$playerLeftPaddingLeftDesktop} +
        2px;
      $paddingTablet: #{$playerLeftPaddingRightTablet} + #{$playerLeftPaddingLeftTablet} +
        2px;
      $paddingMobile: #{$playerLeftPaddingRightMobile} + #{$playerLeftPaddingLeftMobile} +
        2px;
      $paddingSmallMobile: #{$playerLeftPaddingRightSmallMobile} + #{$playerLeftPaddingLeftSmallMobile} +
        2px;
      @include media(
        (
          left: (
            all: calc((#{$paddingDesktop}) + (#{$playerLeftDesktop})),
            tablet: calc((#{$paddingTablet}) + (#{$playerLeftTablet})),
            mobile: calc((#{$paddingMobile}) + (#{$playerLeftMobile})),
            smallMobile:
              calc((#{$paddingSmallMobile}) + (#{$playerLeftSmallMobile})),
          ),
          min-width: (
            smallMobile: 100vw / 2,
          ),
          width: (
            all:
              calc(
                100% - (#{$playerRightDesktop}) - (#{$playerLeftDesktop}) -
                  (#{$paddingDesktop})
              ),
            tablet:
              calc(
                100% - (#{$playerRightTablet}) - (#{$playerLeftTablet}) -
                  (#{$paddingTablet})
              ),
            mobile:
              calc(
                100% - (#{$playerRightMobile}) - (#{$playerLeftMobile}) -
                  (#{$paddingMobile})
              ),
            smallMobile:
              calc(
                100% - (#{$playerRightSmallMobile}) -
                  (#{$playerLeftSmallMobile}) - (#{$paddingSmallMobile})
              ),
          ),
          max-width: (
            all:
              calc(
                100% - (#{$playerRightDesktop}) - (#{$playerLeftDesktop}) -
                  (#{$paddingDesktop})
              ),
            tablet:
              calc(
                100% - (#{$playerRightTablet}) - (#{$playerLeftTablet}) -
                  (#{$paddingTablet})
              ),
            mobile:
              calc(
                100% - (#{$playerRightMobile}) - (#{$playerLeftMobile}) -
                  (#{$paddingMobile})
              ),
            smallMobile:
              calc(
                100% - (#{$playerRightSmallMobile}) -
                  (#{$playerLeftSmallMobile}) - (#{$paddingSmallMobile})
              ),
          ),
        )
      );

      #mp-panel {
        padding: 0;
        .wrapper {
          // & > div {
          .mp-player-button {
            .mp-button {
              &.js-play {
                width: $playerIconHeight;
                height: $playerIconHeight;
              }
            }
          }

          .mp-control {
            .mp-player-button {
              &[data-button='list'] {
                @include media(
                  (
                    border-width: (
                      // tablet: 0
                        smallMobile: 0,
                    ),
                    // width: (
                      //   tablet: 0
                      // ),
                      // max-width: (
                      //   tablet: 0
                      // ),
                      // min-width: (
                      //   tablet: 0
                      // ),
                      // overflow: (
                      //   tablet: hidden
                      // ),,
                  )
                );
              }

              &[data-button='volume'] {
                display: none;
              }

              div {
                &.mp-volume {
                  &:hover {
                    .mp-volume__edit,
                    .mp-volume__bg {
                      height: $playerHeight;
                    }
                  }
                }
              }
            }
          }
          // }

          .mp-share {
            width: 0px;
            max-width: 0px;

            border-width: 0;
          }
        }
      }
    }
  }

  .mp-player {
    position: fixed;
    z-index: $zIndexMultiPlayer;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 100vw;
    min-height: $playerHeight;

    transition: $transition;

    border-bottom: 2px solid;

    * {
      border-color: inherit !important;
    }

    &[data-theme='black'] {
      border-color: rgba(255, 255, 255, 0.2);
      background: $black;

      & > .loader {
        background: $black;
      }
      .mp-song__info:after {
        background: linear-gradient(
          270deg,
          $black 0%,
          rgba(0, 0, 0, 0) 100%
        ) !important;
      }
    }

    &[data-theme='blue'] {
      border-color: rgba(255, 255, 255, 0.2);
      background: $blue;

      & > .loader {
        background: $blue;
      }

      .mp-song__info {
        .mp-live-title {
          color: $darkGray !important;
        }

        &:after {
          background: linear-gradient(
            270deg,
            $blue 0%,
            fade-out($blue, 1) 100%
          ) !important;
        }
      }
    }

    &[data-theme='red'] {
      border-color: rgba(255, 255, 255, 0.2);
      background: $red;

      & > .loader {
        background: $red;
      }

      #mp {
        #mp-panel {
          color: $white;

          .wrapper {
            & > div {
              .mp-control {
                .mp-player-button {
                  & > div.mp-volume {
                    .mp-volume__edit {
                      background: $gray;
                      .noUi-base,
                      .noUi-origin,
                      .noUi-target {
                        background: $gray;

                        .noUi-connect,
                        .noUi-origin {
                          background: $blue;
                        }

                        .noUi-handle {
                          background: $gray;
                        }
                      }
                    }
                  }
                }
              }

              .mp-song__info {
                .mp-live-title {
                  color: $white;
                }
              }
            }
          }
        }
      }

      #mp #mp-panel {
        .mp-control {
          display: flex;

          padding: 0;

          .mp-player-button {
            & > div {
              svg {
                polygon {
                  stroke: $white;
                }
                path {
                  stroke: $white;
                }
                line {
                  stroke: $white;
                }
              }
            }
          }
        }

        .mp-player-button {
          &:hover {
            background: rgba(0, 0, 0, 0.25);
          }
        }

        #mp-play {
          rect {
            fill: $red;
            stroke: $red;
          }

          path {
            fill: $red;
            stroke: $red;
          }

          .mp-process {
            path {
              fill: transparent;
              stroke: $red;
            }
          }

          circle {
            fill: $white;
          }
        }

        .mp-song__info:after {
          background: linear-gradient(
            270deg,
            $red 0%,
            fade-out($red, 1) 100%
          ) !important;
        }
      }
    }

    .mp-control {
      svg {
        width: 40px !important;
        height: 40px !important;
      }
    }

    & > .loader {
      position: absolute;
      z-index: 160;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100vw;
      height: 100%;

      background: transparent;

      & > .svg-image {
        height: 50%;
      }

      & > div {
        width: 40px;
        height: 40px;
      }
    }

    #mp-overlay {
      z-index: $zIndexOverlayPlayer;
      top: 80px !important;
      bottom: initial !important;

      height: calc(100% - 80px) !important;
      padding: 0 !important;
      padding-top: $playerHeight;

      transform: translateY(-100%) !important;

      -webkit-perspective: 1000;

      .mpl {
        max-width: initial;
      }
    }

    #mp.mp_opened #mp-overlay {
      transform: translateY(0%) !important;
    }

    .mp-player-button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: $playerHeight;
      min-height: $playerHeight;
      padding: 0;

      transition: $playerTransition;

      @include media(
        (
          width: (
            all: $playerHeight,
            smallMobile: calc(100vw / 4),
          ),
          min-width: (
            all: $playerHeight,
            smallMobile: calc(100vw / 4),
          ),
        )
      );

      &.border-right {
        border-right: $lightBorder;
      }

      &.border-left {
        border-left: $lightBorder;
      }

      &:hover {
        background: $lightBlack;
      }
    }

    .mp-clappr-player {
      background: $black;
    }

    .mp-custom-panel {
      position: fixed;
      z-index: 1030;
      top: 0;
      left: 0;

      width: 100%;

      background: inherit;
    }

    .mp-custom-left {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;

      display: flex;
      overflow: hidden;
      align-items: center;

      width: 0;
      height: 100%;
      padding: 0;

      transition: $playerTransition;

      background: transparent;

      .logotype-component {
        width: 0px;
        min-width: 0px;
        margin-right: 10px;

        transition: $playerTransition;

        svg {
          width: 100%;
        }
      }

      .frequency {
        @include media(
          (
            display: (
              tablet: none,
            ),
          )
        );
      }
    }

    .mp-custom-right {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;

      display: flex;
      overflow: hidden;

      width: 0;
      height: 100%;

      transition: $playerTransition;

      background: transparent;
    }

    #mp {
      position: relative;
      z-index: 150;
      bottom: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      max-width: 100%;
      height: auto;
      height: 100%;

      transition: $playerTransition;

      background: transparent;

      #mp-panel {
        z-index: 150;

        width: 100%;

        transition: $playerTransition;

        background: transparent;

        .wrapper {
          display: flex;
          justify-content: space-between;

          max-width: initial;
          height: auto;
          padding: 0;

          .mp-share {
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-content: center;

            height: 100%;

            transition: $playerTransition;

            border-left: $lightBorder;

            @include media(
              (
                border-width: (
                  tablet: 0,
                ),
                width: (
                  all: 340px,
                  tablet: 0,
                ),
                max-width: (
                  all: 340px,
                  tablet: 0,
                ),
              )
            );

            .mp-share-label {
              display: flex;
              align-items: center;

              color: $veryLightBlack;
            }

            .mp-share-society {
              display: flex;
              align-items: center;

              margin-left: 40px;

              .mp-share-society-item {
                display: flex;

                margin: 0 20px;

                cursor: pointer;

                path {
                  transition: $playerTransition;
                }

                &.vk {
                  path {
                    &:last-child {
                      fill: $black;
                    }
                  }
                }

                &:hover {
                  path {
                    fill: $white;
                  }
                }

                &:first-child {
                  margin-left: 0;
                }
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

          .mp-control {
            display: flex;

            padding: 0;

            .mp-player-button {
              & > div {
                svg {
                  path {
                    transition: $playerTransition;
                  }
                  polygon {
                    transition: $playerTransition;
                  }
                }

                &:hover {
                  svg {
                    polygon {
                      stroke: $white;
                    }
                    path {
                      stroke: $white;
                    }
                  }
                }

                &.mp-list {
                  position: static;

                  display: flex;
                  align-items: center;
                  justify-content: center;

                  width: 100%;
                  height: 100%;
                  margin: 0;

                  cursor: pointer;

                  &.mp-active {
                    svg {
                      transform: rotate(-180deg);
                    }
                  }

                  svg {
                    height: 40px;

                    transition: $playerTransition;
                  }
                }

                &.mp-volume {
                  position: static !important;

                  display: flex;
                  align-items: center;
                  justify-content: center;

                  width: 100%;
                  height: 100%;

                  &:hover {
                    .mp-volume__edit,
                    .mp-volume__bg {
                      height: 280px;
                    }
                  }

                  svg {
                    position: absolute;
                    z-index: 20;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    margin: auto;

                    transition: $playerTransition;
                    pointer-events: none;

                    opacity: 0;

                    &.half {
                      path {
                        &:first-of-type {
                          stroke: transparent;
                        }
                      }
                    }

                    &.active {
                      opacity: 1;
                    }
                  }

                  .mp-volume__edit {
                    top: initial !important;
                    bottom: 0 !important;

                    display: initial !important;
                  }

                  .mp-volume__edit,
                  .mp-volume__bg {
                    top: 0;
                    left: 0;

                    overflow: hidden;

                    width: 100%;
                    height: 0;
                    margin: 0;
                    padding: 0;

                    transition: $transition;

                    border-radius: 0;
                    background: $black;

                    #mp-range {
                      position: absolute;
                      bottom: 0;
                      left: 0;

                      height: 100% !important;
                    }

                    .noUi-base,
                    .noUi-origin,
                    .noUi-target {
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      width: 100%;

                      background: black;

                      .noUi-connect,
                      .noUi-origin {
                        transition: $transition;

                        background: $red;
                      }

                      .noUi-handle {
                        z-index: 30;
                        left: 0;

                        width: 100%;
                        height: 10px;
                        margin: 0;

                        opacity: 0.5;
                        border: none;
                        border-radius: 0;
                        background: black;
                      }
                    }
                  }
                }
              }
            }
          }

          .mp-group {
            flex-direction: row !important;
            flex-grow: 1;
          }

          .mp-song {
            overflow: hidden;
            align-items: center;
            flex: 1 1 auto;

            width: initial;
            height: initial;
            padding: 0;

            pointer-events: none;

            color: white;

            @include media(
              (
                flex: (
                  smallMobile: 1,
                ),
                justify-content: (
                  all: flex-start,
                  smallMobile: center,
                ),
                display: (
                  all: flex,
                ),
                width: (
                  smallMobile: 0,
                ),
                margin: (
                  all: 0 0 0 20px,
                  smallMobile: 0,
                ),
              )
            );

            &__cover {
              position: relative;

              display: block !important;

              margin-right: 20px;

              @include media(
                (
                  margin: (
                    all: 0 20px 0 0,
                    tablet: 0,
                    smallMobile: 0,
                  ),
                  width: (
                    tablet: 0 !important,
                  ),
                  min-width: (
                    tablet: 0 !important,
                  ),
                  overflow: (
                    tablet: hidden !important,
                  ),
                )
              );
            }

            &__cover,
            &__cover-image {
              float: none;
              overflow: hidden;

              width: $playerIconHeight;
              min-width: $playerIconHeight;
              height: $playerIconHeight;

              transition: $playerTransition;

              svg {
                transition: $playerTransition;
              }

              img {
                width: 100%;
                max-width: initial;
                height: 100%;
                max-height: initial;

                transition: $playerTransition;

                @include media(
                  (
                    display: (
                      all: block,
                      laptop: block,
                      tablet: block,
                      mobile: block,
                    ),
                  )
                );
              }
            }

            &__info {
              position: relative;

              overflow: hidden;
              align-items: flex-start;
              flex-direction: column;
              justify-content: flex-start;

              width: 100%;
              max-width: initial;
              max-width: initial;
              margin-left: 0;

              white-space: nowrap;

              font-size: 24px;

              @include media(
                (
                  display: (
                    all: grid,
                    smallMobile: none !important,
                  ),
                )
              );

              &-group {
                display: flex;
              }

              &:after {
                position: absolute;
                z-index: 100;
                right: 0;

                display: block;

                width: 150px;
                height: 100%;

                content: '';

                opacity: 1;
                background: $black;
                background: linear-gradient(
                  270deg,
                  $black 0%,
                  rgba(0, 0, 0, 0) 100%
                );
              }

              &-artist {
                display: flex;
              }

              &-track-name {
                display: flex;

                margin-left: 5px;
              }

              &-station {
                display: none;
              }

              .mp-live-title {
                display: flex;
                align-items: center;

                margin-bottom: 2px;
                padding-top: 5px;

                color: $veryLightBlack;
              }
            }
          }

          .mp-player-button {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0;

            .mp-button {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;

              width: $playerIconHeight;
              height: $playerIconHeight;
              margin: auto;
              padding: 0;

              border-radius: 100%;
              background: $red;

              svg {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                transition: opacity 500ms;
              }
            }
          }
        }
      }
    }
  }

  .mp-player-button[data-button='volume'] {
    display: none !important;
  }

  #mp #mp-banner {
    z-index: $zIndexAdWolfBanner;
    bottom: 0px;
    left: 0;

    width: 100vw;
  }
}
