@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
  .slick-initialized {
    .slick-track {
      display: flex;
    }
    
    .slick-slide {
      display: flex;

      div {
        outline: none;
      }

      & > div {
        display: flex;
        flex: 1 1 auto;
      }
    }
  }
}
