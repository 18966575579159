@import '../../css/guideline.scss';

body {
  .artist-head.active {
    .image {
      @include media(
        (
          width: (
            all: 0,
            mobile: 100%
          ),
          min-width: (
            all: 0,
            mobile: 100%
          ),
          margin-right: (
            all: 0,
            mobile: 0
          )
        )
      );
    }
  }

  .artist-head {
    display: flex;
    color: $white;
    background: $red;
    box-sizing: border-box;
    justify-content: space-between;

    @include media(
      (
        padding: (
          all: 40px,
          mobile: 20px
        ),
        flex-direction: (
          mobile: column
        )
      )
    );

    & > div {
      display: flex;
    }

    .information {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include media(
        (
          flex-direction: (
            all: row,
            mobile: column
          ),
          align-items: (
            all: flex-end,
            mobile: flex-start
          )
        )
      );

      .about-artist {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media(
          (
            margin-bottom: (
              all: 0,
              mobile: 20px
            )
          )
        );
      }

      .name {
        margin-top: 20px;
      }

      .buttons-block {
        display: flex;

        & > div {
          transition: background 500ms;

          &:hover {
            .value {
              color: $black;
            }

            .svg-image {
              svg {
                path {
                  stroke: $black;
                }
              }
            }
          }

          .svg-symbol {
            width: 20px;
            height: 20px;
            margin-right: 6px;

            svg {
              path {
                fill: $black;
                transition: $transition;
              }
            }
          }

          .svg-image {
            width: 40px;
            height: 40px;

            svg {
              path {
                transition: $transition;
                stroke: $white;
              }
            }
          }

          .value {
            transition: $transition;
            padding-top: 4px;
          }

          height: 60px;
          width: 200px;
          padding: 0;
          margin: 0 10px;
          box-sizing: border-box;

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .image {
      overflow: hidden;
      background: $black;
      transition: $transition;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center 0;

      @include media(
        (
          margin-right: (
            all: 40px,
            mobile: 0
          ),
          margin-bottom: (
            all: 0,
            mobile: 20px
          ),
          height: (
            all: 200px,
            mobile: 0
          ),
          min-height: (
            all: 200px,
            mobile: 0
          ),
          width: (
            all: 200px,
            mobile: 100%
          ),
          min-width: (
            all: 200px,
            mobile: 100%
          ),
          padding-top: (
            mobile: 100%
          )
        )
      );
    }

    .social {
      width: 60px;
      height: 60px;
      display: flex;
      cursor: pointer;
      position: relative;

      @include media(
        (
          width: (
            all: 60px,
            mobile: 100%
          )
        )
      );

      &.active {
        .share-button-container {
          .share-button {
            @include media(
              (
                transform: (
                  all: translateX(-100%),
                  mobile: translateX(100%)
                )
              )
            );
          }
        }

        .share {
          width: calc(60px * 4 + 40px);
          left: calc(calc(-55px * 4) - 100px);
        }
      }

      .share {
        transition: $transition;
        align-items: center;
        display: flex;
        height: 100%;
        z-index: 10;
        width: 0;
        left: 0;
        top: 0;

        @include media(
          (
            position: (
              all: absolute,
              mobile: static
            )
          )
        );
      }

      .share-button-container {
        overflow: hidden;
        height: 100%;
        width: 60px;
        min-width: 60px;
        display: flex;
        background: $red;
        align-items: center;
        box-sizing: border-box;
        border: 2px solid $white;
        z-index: 15;
        margin-right: 20px;

        .share-button {
          width: 100%;
          display: flex;
          align-items: center;
          transition: $transition;

          @include media(
            (
              flex-direction: (
                all: row,
                mobile: row-reverse
              )
            )
          );

          .svg-image {
            width: 100%;
            min-width: 100%;

            svg {
              width: 40px;
              height: 40px;
              margin: auto;
              stroke: $white;

              path {
                stroke: $white;
              }
            }
          }
        }
      }
    }
  }
}
