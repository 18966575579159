@import '../../css/guideline.scss';

body {
  .podcasts {
    color: $white;
    background-color: $lightBlack;

    .line-container {
      box-sizing: border-box;
      width: 100%;
      padding: 0 40px;

      &:before {
        display: block;

        width: 100%;
        height: 2px;

        content: '';

        opacity: 0.2;
        background: $white;
      }
    }

    .podcast {
      display: flex;
      overflow: auto;
      overflow-y: hidden;
      justify-content: space-between;

      box-sizing: border-box;
      max-height: 380px;
      padding: 40px 40px 40px;

      transition: $transition;

      .podcast-column {
        display: flex;
        flex-direction: column;

        width: 100%;
        margin: 0 20px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &[data-column='preview'] {
          align-items: flex-start;
          justify-content: flex-start;

          .image-container {
            width: 300px;

            transition: $transition;

            .image {
              height: 0;
              padding-top: 100%;

              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
            }
          }
          .podcast-social {
            display: none;

            margin-top: 50px;

            opacity: 0;
            border-top: 2px solid $gray;
          }
        }

        &[data-column='content'] {
          justify-content: space-between;

          .podcast-top-block {
            min-height: 200px;

            transition: $transition;

            .podcast-title {
              margin-top: 10px;
            }
          }

          .podcast-bottom-block {
            // TODO
            // .podcast-description {
            //   margin-top: 60px;
            // }

            .podcast-progress {
              width: 100%;
              height: 0px;
              margin-bottom: 0px;

              transition: $transition;

              background: $lightBlack;

              div {
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;

                display: flex;

                height: 100%;

                background: $white;

                &.hover {
                  z-index: 5;

                  opacity: 0.5;
                  background: $white;
                }
              }
            }

            .podcast-control {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .podcast-player-control {
                display: flex;
                align-items: center;

                .podcast-duration {
                  display: flex;

                  padding-top: 4px;

                  &-value {
                    margin-left: 20px;
                  }
                }
              }

              .svg-image.arrow {
                transition: $transition;

                path {
                  transition: $transition;

                  stroke: $white;
                }
              }

              .podcast-play {
                position: relative;

                display: inline-flex;

                width: 80px;
                height: 80px;

                cursor: pointer;

                .svg-image {
                  position: absolute;
                  top: 0;
                  left: 0;

                  width: 100%;
                  height: 100%;

                  transition: $transition;

                  opacity: 0;

                  &.pause {
                    transform: translateX(-25px);
                  }

                  &.play {
                    transform: translateX(-28px);
                  }

                  &.active {
                    opacity: 1;
                  }

                  & > div {
                    height: 100%;
                  }

                  svg {
                    display: flex;

                    width: 100%;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }

      &.open {
        max-height: 1160px;

        background-color: $black;

        .podcast-column {
          &[data-column='content'] {
            .podcast-progress {
              position: relative;

              height: 4px;
              margin-bottom: 60px;

              cursor: pointer;
            }

            .podcast-control {
              .svg-image.arrow {
                transform: rotate(-90deg);
                &:hover {
                  path {
                    stroke: $white;
                  }
                }

                path {
                  stroke: $darkGray;
                }
              }
            }
          }

          &[data-column='preview'] {
            .image-container {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
