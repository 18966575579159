@import '../../css/guideline.scss';

body {
  .show-schedules {
    display: flex;

    @include media(
      (
        flex-direction: (
          all: column,
          mobile: column,
        ),
        margin-top: (
          all: 0,
          mobile: 30px,
        ),
      )
    );

    &.is-show-head {
      .show-schedule {
        @include media(
          (
            width: (
              all: 220px,
              tablet: 180px,
              mobile: 160px,
            ),
            min-height: (
              all: 220px,
              tablet: 180px,
              mobile: 160px,
            ),
          )
        );
      }
    }

    .show-schedule-container {
      & + .show-schedule-container {
        @include media(
          (
            margin-top: (
              all: 20px,
              mobile: 20px,
            ),
          )
        );
      }
    }

    .show-schedule {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;

      box-sizing: border-box;
      padding: 20px;

      text-transform: uppercase;

      color: $white;
      background: $red;

      @include media(
        (
          width: (
            all: 160px,
            mobile: 120px,
          ),
          min-height: (
            all: 160px,
            mobile: 120px,
          ),
        )
      );

      & > div:first-child {
        margin-bottom: 20px;
      }
    }
  }
}
