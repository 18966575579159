@import '../../css/guideline.scss';

$proportion: 141.05%;

body {
  .slick-slide {
    .person-container {
      width: 100%;
    }
  }

  .person-container {
    position: relative;

    .person {
      top: 0;
      left: 0;
      height: 0;
      width: 100%;
      overflow: hidden;
      position: relative;
      padding-top: $proportion;

      background-color: $blue;
      background-repeat: no-repeat;
      background-position: center 0;

      .image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    @include media(
      (
        width: (
          all: 33.33vw,
          tablet: 50vw,
          mobile: 100vw
        )
      )
    );

    &:before {
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 10;
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: $black;
      position: absolute;
      transition: $transition;
    }

    &:hover {
      &:before {
        opacity: $overlayOpacity;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .content {
      z-index: 10;
      bottom: 40px;
      color: $white;
      display: flex;
      position: absolute;
      flex-direction: column;

      @include media(
        (
          width: (
            all: calc(100% - 80px),
            mobile: calc(100% - 40px)
          ),
          padding: (
            all: 0 40px,
            mobile: 0 20px
          )
        )
      );

      .line {
        height: 1px;
        width: 100%;
        opacity: 0.5;
        margin: 15px 0;
        background: $white;
      }
    }

    .preset {
      z-index: 5;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 110%;
      height: 110%;
    }
  }
}
