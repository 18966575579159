@import '../../css/guideline.scss';

$white: #fff;
$black: #000;
$radius: 0;

body {
  .modal {
    position: fixed;
    z-index: $zIndexModal;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100%;
    max-height: 100%;

    &.visible {
      .fade {
        opacity: 0.75;
      }
      .content {
        transform: translate(0, 0);

        opacity: 1;
      }
    }

    .content-scroll {
      overflow-y: scroll;

      width: 100%;
      height: calc(100% - 120px);
      max-height: calc(100% - 120px);
      padding: 60px 0;

      @media screen and (max-width: 600px) {
        overflow-x: hidden;

        height: initial;
        max-height: 100%;
      }
    }

    .content {
      position: relative;
      z-index: 5;

      display: flex;
      flex-direction: column;

      width: 90%;
      max-width: calc(800px - 60px);
      margin: 0 auto;
      padding: 30px 30px;

      transform: translate(0, -10vh);

      opacity: 0;
      border-radius: $radius;
      background: $white;

      @include media(
        (
          transition: (
            all: $transition
          ),
          transform: (
            all: translate(0, calc(var(--vh, 1vh) * -10)),
            mobile: translate(0, calc(var(--vh, 1vh) * -15))
          ),
          max-width: (
            all: calc(800px - 60px)
          ),
          width: (
            all: calc(100% - 60px)
          )
        )
      );
    }

    .arrow-back {
      position: fixed;
      z-index: 5;
      top: 30px;
      left: 30px;

      width: 36px;
      height: 36px;

      cursor: pointer;
      transition: opacity 0.5s;

      opacity: 0.5;
      background: transparent;
      background: url('/images/arrow-back.svg') no-repeat center;
      background-size: contain;

      img {
        width: 100%;
      }

      &:hover {
        opacity: 1;
      }
    }

    .close {
      position: absolute;
      top: 12px;
      right: 12px;

      width: 14px;
      height: 14px;

      cursor: pointer;
    }

    .modal-title {
      margin-bottom: 20px;

      font-size: 19px;
      font-weight: 500;

      &.center {
        margin: 0 auto 20px;
      }
    }

    .fade {
      position: fixed;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;

      opacity: 0;
      background: $black;

      @media screen and (max-width: 600px) {
        -webkit-transition: all 0 ease;
        -moz-transition: all 0 ease;
        -o-transition: all 0 ease;
        transition: all 0 ease;
      }
    }
  }
}

body {
  .modal {
    .close {
      top: 25px;
      right: 30px;

      width: 24px;
      height: 24px;

      & > div,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
