@import '../../css/guideline.scss';

body {
  .presenter-header {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;

    color: $white;

    background: linear-gradient(to top, $black 0%, $red 100%);
    background-size: cover;
    background-color: $red;
    background-repeat: no-repeat;
    background-position: center 0;

    /* IE9, iOS 3.2+ */
    background-image: -webkit-gradient(
      linear,
      0% 100%,
      0% 0%,
      color-stop(0, $black),
      color-stop(1, $red)
    );
    /* IE10+ */
    background-image: repeating-linear-gradient(to top, $black 0%, $red 100%);
    background-image: -ms-repeating-linear-gradient(
      bottom,
      $black 0%,
      $red 100%
    );

    height: calc(100vh - #{$playerHeight});

    @include media(
      (
        height: (
          all: calc(var(--vh, 1vh) * 100 - #{$playerHeight}),
          mobile: auto
        ),
        padding: (
          all: 40px 40px 20px,
          mobile: 20px 20px 20px
        ),
        background-image: (
          mobile: none !important
        )
      )
    );

    .presenter-role {
      margin-bottom: 20px;
    }

    .presenter-name {
      line-height: 1;
      letter-spacing: -2px;
      display: inline-block;

      @include media(
        (
          font-size: (
            all: 140px,
            mobile: 44px
          )
        )
      );
    }

    .presenter-image {
      overflow: hidden;
      margin-bottom: 100px;
      transition: $transition;
      background-position: center 0;
      background-repeat: no-repeat;
      background-color: $black;
      background-size: cover;

      @include media(
        (
          width: (
            all: 320px,
            mobile: 100%
          ),
          height: (
            all: 320px,
            mobile: 0
          ),
          padding-top: (
            mobile: 100%
          )
        )
      );
    }
  }
}
