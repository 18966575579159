@import '../../css/guideline.scss';

$HEADER_BORDER: 2px;

body {
  .nav-wrapper {
    top: 0;
    left: 0;
    z-index: 120;
    display: flex;
    position: absolute;

    nav {
      width: 100%;
      display: flex;
      margin-top: 30px;
      align-items: flex-end;
      background: transparent;
      justify-content: flex-end;

      @include media(
        (
          display: (
            all: flex,
            tablet: none
          )
        )
      );

      .navigation-element {
        color: $white;
        display: flex;
        margin: 0 10px;
        cursor: pointer;
        align-items: center;
        transition: $transition;

        &:before {
          opacity: 0;
          width: 10px;
          content: '';
          height: 10px;
          display: block;
          margin-right: 6px;
          margin-bottom: 3px;
          background: $white;
          border-radius: 100%;
          transition: $transition;
        }

        &.active {
          &:hover {
            &:before {
              opacity: 1;
            }
          }

          &:before {
            opacity: 1;
          }
        }

        &:hover {
          color: $white;

          &:before {
            opacity: 0.75;
          }
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
