@import '../../css/guideline.scss';

body {
  .news-block {
    .line-container {
      background: transparent;
    }
  }

  .line-container {
    width: 100%;

    background: $white;

    &.is-with-padding {
      @include media(
        (
          padding-top: (
            all: 40px,
            mobile: 10px,
          ),
          padding-bottom: (
            all: 40px,
            mobile: 10px,
          ),
        )
      );
    }

    &.vertical {
      width: auto;

      .wrapper {
        width: initial;
        height: 100%;
        margin: 0 auto;
        padding: 20px 0;
      }

      .line {
        box-sizing: border-box;
        width: 2px;
        height: 100%;
      }
    }

    .line {
      display: flex;

      width: 100%;
      height: 2px;
      margin: 0 auto;

      background: $black;
    }
  }

  .line-container {
    &.dark {
      &.news-line {
        .line {
          background: $darkGray;
        }
      }

      .line {
        background: $white;
      }
    }

    &.black {
      &.news-line {
        .line {
          background: $darkGray;
        }
      }
      .line {
        background: $white;
      }
    }

    &.gray {
      &.news-line {
        .line {
          background: $darkGray;
        }
      }
      .line {
        background: $black;
      }
    }

    &.dark {
      background: $lightBlack;
    }
    &.black {
      background: $black;
    }
    &.gray {
      background: $gray;
    }
  }
}
