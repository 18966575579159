@import '../../css/guideline.scss';

body {
  .show-item {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 0;
    padding-bottom: 48%;

    @include media(
      (
        padding-bottom: (
          all: 48%,
          mobile: 130%,
        ),
      )
    );

    &:before {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      content: '';
      transition: $transition;
      pointer-events: none;

      opacity: 0;
      background: $black;
    }

    .poster {
      position: absolute;
      z-index: 5;
      right: 0;
      bottom: 0;
      left: 0;

      margin: auto;

      @include media(
        (
          width: (
            all: 55.77%,
            mobile: calc(100% - 40px),
          ),
          padding-top: (
            all: 44.232%,
            mobile: 68%,
          ),
          height: (
            all: 0,
          ),
        )
      );
    }

    .show-content {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;

      display: flex;

      box-sizing: border-box;
      width: 100%;
      height: 100%;

      @include media(
        (
          padding: (
            all: 3.8%,
            mobile: 20px,
          ),
          flex-direction: (
            mobile: column-reverse,
          ),
          justify-content: (
            all: space-between,
            mobile: flex-end,
          ),
        )
      );

      .schedules {
        display: flex;
        flex-direction: column;
      }

      .show-title-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding-left: 20px;

        @include media(
          (
            width: (
              all: 340px,
              mobile: none,
            ),
            padding-left: (
              all: 20px,
              mobile: 0,
            ),
          )
        );

        .show-title {
          display: flex;
          flex-direction: column;

          word-break: break-word;

          @include media(
            (
              align-items: (
                all: flex-end,
                mobile: flex-start,
              ),
              text-align: (
                all: right,
                mobile: left,
              ),
            )
          );
        }
      }

      .presenters {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        margin-top: 20px;

        @include media(
          (
            justify-content: (
              all: flex-end,
              mobile: flex-start,
            ),
          )
        );

        .presenter {
          overflow: hidden;

          width: 50px;
          height: 50px;
          margin-bottom: 20px;

          border-radius: 100%;
          background-color: $red;
          background-repeat: no-repeat;
          background-position: center 0;
          background-size: cover;

          @include media(
            (
              margin-right: (
                all: 0px,
                mobile: 20px,
              ),
            )
          );

          & + .presenter {
            @include media(
              (
                margin-left: (
                  all: 20px,
                  mobile: 0px,
                ),
              )
            );
          }
        }
      }
    }

    .last-show {
      @include media(
        (
          display: (
            tablet: none,
          ),
        )
      );

      .last-show-poster {
        width: 140px;
        height: 0;
        height: 80px;
        margin-bottom: 20px;
      }
    }

    &:hover {
      &:before {
        opacity: $overlayOpacity;
      }
    }

    &.no-schedule {
      .show-content {
        justify-content: flex-end;
      }
    }

    &.with-last-show {
      .poster {
        @include media(
          (
            left: (
              all: 3.8%,
              tablet: 0,
            ),
            right: (
              all: initial,
              tablet: 0,
            ),
          )
        );
      }

      .show-content {
        .show-title-container {
          @include media(
            (
              border-left: (
                all: $lightBorder,
                tablet: none,
              ),
              border-color: (
                all: inherit,
              ),
            )
          );
        }
      }
    }
  }
}
