@import '../../css/guideline.scss';

@keyframes dong {
  0% {
    height: 30%;
  }
  20% {
    height: 80%;
  }
  40%,
  100% {
    height: 30%;
  }
}

body {
  .modal {
    &[data-modal='authorization'] {
      .politic-link {
        transition: $transition;

        &:hover {
          opacity: 0.5;
        }
      }

      .close {
        @include media(
          (
            top: (
              all: 25px,
              mobile: 20px
            ),
            right: (
              all: 30px,
              mobile: 20px
            ),
            width: (
              all: 24px,
              mobile: 24px
            ),
            height: (
              all: 25px,
              mobile: 24px
            )
          )
        );
      }

      .content-scroll {
        @include media(
          (
            padding: (
              all: 60px 0,
              tablet: 40px 0,
              mobile: 20px 0
            ),
            height: (
              all: calc(100% - 120px),
              tablet: calc(100% - 80px),
              mobile: calc(100% - 40px)
            ),
            max-height: (
              all: calc(100% - 120px),
              tablet: calc(100% - 80px),
              mobile: calc(100% - 40px)
            )
          )
        );

        .content {
          width: calc(100% - 40px);
          max-width: 720px;
          padding: 0;

          background: transparent;
        }

        #my-signin2 {
          display: none;
        }

        .authorization-content {
          display: flex;
          flex-direction: column;

          background: $gray;

          &.society-content {
            margin-bottom: 20px;
          }

          .authorization-society {
            display: flex;

            width: 100%;
            margin-top: 2vw;

            @include media(
              (
                height: (
                  all: 160px,
                  mobile: 60px
                )
              )
            );

            & > div {
              display: flex;

              width: 100%;
            }

            .svg-image {
              display: flex;
              align-items: center;
              justify-content: center;

              width: 100%;

              transition: $transition;

              svg {
                width: auto;

                @include media(
                  (
                    height: (
                      all: 52px,
                      mobile: 23px
                    )
                  )
                );
              }

              &:hover {
                &.vk {
                  background: darken($vk, 10%);
                }

                &.fb {
                  background: darken($fb, 10%);
                }

                &.google {
                  background: darken($white, 10%);
                }
              }

              &.vk {
                background: $vk;

                svg {
                  width: auto;

                  @include media(
                    (
                      height: (
                        all: 40px,
                        mobile: 17px
                      )
                    )
                  );
                }
              }

              &.fb {
                background: $fb;
              }

              &.google {
                background: $white;
              }
            }
          }

          .authorization-title {
            max-width: 80%;

            text-transform: uppercase;

            @include media(
              (
                padding: (
                  all: 30px 30px 50px,
                  mobile: 20px
                )
              )
            );
          }

          .authorization-body {
            @include media(
              (
                padding: (
                  all: 0 30px,
                  mobile: 0 20px
                )
              )
            );

            form {
              display: flex;
              flex-direction: column;

              padding-bottom: 40px;

              .form-group {
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding: 10px 0;

                @include media(
                  (
                    flex-direction: (
                      all: row,
                      mobile: column
                    )
                  )
                );

                &.checkbox {
                  flex-direction: row;
                }

                &:first-child {
                  padding-top: 0;
                }
                &:last-child {
                  padding-bottom: 0;
                }

                .smart-input {
                  color: black
                }

                label:not(.smart-input) {
                  display: flex;

                  cursor: pointer;

                  @include media(
                    (
                      align-items: (
                        all: center,
                        mobile: flex-start
                      )
                    )
                  );

                  a {
                    margin-left: 5px;

                    color: $vk;
                  }
                }
              }

              button {
                width: 100%;
                margin-top: 40px;
                padding: 40px;

                cursor: pointer;
                transition: $transition;

                color: $white;
                border: none;
                outline: none;
                background: $black;

                span {
                  transition: $transition;

                  opacity: 1;
                }

                &:hover span {
                  opacity: 0.5;
                }

                &:active span {
                  opacity: 0.3;
                }

                .svg-image > div {
                  align-items: center;
                  justify-content: center;

                  height: 30px;
                  svg {
                    width: 37px;
                    height: 10px;
                  }
                }

                .loader {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  justify-content: center;

                  box-sizing: border-box;
                  width: 100%;
                  height: 30px;

                  .line {
                    width: 5px;
                    height: 30%;
                    margin: 0 1.5px;

                    background-color: $white;

                    &:nth-child(1) {
                      margin-left: 0;

                      animation: dong 1s infinite running;
                      animation-delay: 0s;
                    }
                    &:nth-child(2) {
                      animation: dong 1s infinite running;
                      animation-delay: 0.1s;
                    }
                    &:nth-child(3) {
                      animation: dong 1s infinite running;
                      animation-delay: 0.2s;
                    }
                    &:nth-child(4) {
                      animation: dong 1s infinite running;
                      animation-delay: 0.3s;
                    }
                    &:nth-child(5) {
                      margin-right: 0;

                      animation: dong 1s infinite running;
                      animation-delay: 0.4s;
                    }
                  }
                }
              }
            }
          }
        }

        .authorization-additional-buttons {
          display: flex;
          justify-content: space-between;

          margin-top: 30px;

          @include media(
            (
              flex-direction: (
                all: row,
                mobile: column-reverse
              ),
              align-items: (
                all: center,
                mobile: center
              )
            )
          );

          & > p {
            position: relative;

            display: flex;
            align-items: center;

            cursor: pointer;
            transition: $transition;
            text-transform: uppercase;

            color: $white;

            @include media(
              (
                margin: (
                  all: 0,
                  mobile: 8px 0
                )
              )
            );

            &:first-child {
              margin-bottom: 0;
            }

            &:last-child {
              margin-top: 0;
            }

            &:hover {
              color: $darkGray;

              svg {
                path,
                polyline {
                  stroke: $darkGray;
                }
              }
            }

            .svg-image {
              width: 40px;
              height: 40px;
              margin-left: 20px;

              @include media(
                (
                  width: (
                    all: 40px,
                    mobile: 20px
                  ),
                  height: (
                    all: 40px,
                    mobile: 20px
                  )
                )
              );

              svg {
                width: 100%;
                height: 100%;

                path,
                polyline {
                  transition: $transition;
                }
              }
            }
          }
        }
      }
    }
  }
}
