@import '../../css/guideline.scss';

body {
  .schedule-carousel {
    margin-top: 70px;

    .slick-arrow {
      position: absolute;
      top: 50%;

      width: 30px;
      height: 36px;

      transform: translateY(-50%);

      &:before,
      &:after {
        display: none;
      }

      &.slick-prev {
        left: 0;

        transform: translateY(-50%) rotate(180deg);
      }

      &.slick-next {
        right: 0;
      }
    }

    .slick-list {
      @include media(
        (
          margin: (
            all: 0 5%,
            mobile: 0 15%,
          ),
        )
      );
    }

    .slick-slide {
      display: flex;

      @include media(
        (
          width: (
            all: calc((90vw - 80px) / 12),
            tablet: calc((90vw - 80px) / 6),
            mobile: calc(((100vw - 40px) * 0.7) / 3),
          ),
          min-width: (
            all: calc((90vw - 80px) / 12),
            tablet: calc((90vw - 80px) / 6),
            mobile: calc(((100vw - 40px) * 0.7) / 3),
          ),
          max-width: (
            all: calc((90vw - 80px) / 12),
            tablet: calc((90vw - 80px) / 6),
            mobile: calc(((100vw - 40px) * 0.7) / 3),
          ),
        )
      );

      .slick-item {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 20px 0;

        cursor: pointer;
        text-align: center;
        // transition: $transition;
      }
    }
  }

  .item-day {
    text-transform: uppercase;
  }

  .current {
    color: $white;
    background-color: $black;
  }
}
