@import '../../css/guideline.scss';

.rotation-carousel {
  .slick-slide {
    @include media(
      (
        min-width: (
          all: calc(100vw / 3),
          tablet: calc(100vw / 2),
          mobile: 100vw
        ),
        width: (
          all: calc(100vw / 3),
          tablet: calc(100vw / 2),
          mobile: 100vw
        ),
        max-width: (
          all: calc(100vw / 3),
          tablet: calc(100vw / 2),
          mobile: 100vw
        )
      )
    );
  }
}
