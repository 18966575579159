@import '../../css/guideline.scss';

body {
  .artist-body {
    width: 100%;
    padding-bottom: 60px;
    background-color: $black;

    .artist-information {
      display: flex;
      color: $white;
      background: $black;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;

      @include media(
        (
          margin-left: (
            all: 10px,
            tablet: 0
          ),
          width: (
            all: 45%,
            tablet: 100%
          )
        )
      );

      &:not(.show):not(.no-control):not(.show-songs) {
        .artist-information-text {
          &:after {
            opacity: 1;
          }
        }
      }

      .section-title {
        flex: initial;
      }

      .artist-information-text {
        position: relative;
        overflow: hidden;
        flex: 1 1 auto;
        &:after {
          right: 0;
          bottom: 0;
          opacity: 0;
          width: 100%;
          content: '';
          height: 100px;
          display: block;
          position: absolute;
          transition: $transition;
          background: linear-gradient(to top, $black, rgba(0, 0, 0, 0));
        }
      }
    }

    .artist-songs {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      @include media(
        (
          margin-right: (
            all: 10px,
            tablet: 0
          ),
          width: (
            all: 55%,
            tablet: 100%
          )
        )
      );
    }

    .section-link-container {
      &.active {
        .icon {
          transform: rotate(-180deg);
        }
      }

      .section-link {
        padding: 60px 0 0;
      }
    }

    .artist-body-content {
      display: flex;
      overflow: hidden;

      @include media(
        (
          flex-direction: (
            all: row,
            tablet: column
          )
        )
      );
    }

    .line-container {
      margin-top: 40px;
    }

    .section-title,
    .line-container,
    .songs {
      .wrapper {
        padding: 0;
      }
    }
  }
}
