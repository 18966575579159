@import "../../css/guideline.scss";

$newsPadding: 20px;
$newsCell: 15.25vw;
$newsNextCell: 120px;

.news-card {
  .news-card {
    &__content {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;

      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 20px;

      .information {
        position: relative;
      }
    }
  }

  &[column="1"] {
    @include media(
      (
        width: (
          all: 33.33%,
          tablet: 50%,
          mobile: 100%,
        ),
      )
    );

    .news-card {
      &__content {
        &-wrapper {
          padding-bottom: 100%;
        }
      }
    }

    .additional {
      @include media(
        (
          margin-top: (
            mobile: 80px,
          ),
          display: (
            mobile: flex,
          ),
        )
      );
    }

    .grid {
      @include media(
        (
          width: (
            all: calc(1 * #{$newsCell}),
            mobile: calc((100vw - 40px - 20px) / 2),
          ),
          height: (
            all: calc(1 * #{$newsCell}),
            mobile: calc((100vw - 40px - 20px) / 2),
          ),
        )
      );
    }

    .indicators {
      display: flex;
      align-items: flex-end;

      @include media(
        (
          flex: (
            mobile: 1,
          ),
          width: (
            all: initial,
            mobile: 100%,
          ),
        )
      );
    }

    .views {
      width: $newsCell;
      margin-left: calc(4 * #{$newsPadding});
    }

    .image,
    .triggers.replace {
      @include media(
        (
          height: (
            mobile: 100%,
          ),
          width: (
            mobile: 100%,
          ),
          align-items: (
            mobile: center,
          ),
        )
      );

      .svg-symbol {
        @include media(
          (
            height: (
              mobile: 100% !important,
            ),
            width: (
              mobile: 100% !important,
            ),
          )
        );

        svg {
          @include media(
            (
              height: (
                mobile: 100% !important,
              ),
              width: (
                mobile: auto !important,
              ),
            )
          );
        }
      }
    }
  }

  &[column="-1"] {
    @include media(
      (
        width: (
          all: 33.33%,
          tablet: 50%,
          mobile: 100%,
        ),
      )
    );

    .news-card {
      &__content {
        &-wrapper {
          padding-bottom: 100%;
        }
      }
    }

    .grid {
      width: calc(2 * #{$newsCell});
      height: calc(2 * #{$newsCell});
      margin-top: $newsPadding;
      margin-left: 0;
    }

    .indicators {
      display: flex;
      align-items: flex-end;

      width: initial !important;
    }

    .views {
      width: $newsCell;
      margin-left: calc(4 * #{$newsPadding});
    }
  }

  &[column="2"] {
    @include media(
      (
        width: (
          all: 66.67%,
          mobile: 100%,
        ),
      )
    );

    .news-card {
      &__content {
        &-wrapper {
          padding-bottom: 50%;
        }
      }
    }

    .grid {
      width: calc(2 * #{$newsCell});
      height: calc(2 * #{$newsCell});
    }
  }

  &[column="3"] {
    width: 100%;

    &[column='3'] {
      .news-card {
        &__content {
          position: absolute;
        }
      }

      &.is-inner-page {
        .news-card {
          &__content {
            position: relative;
          }
        }
      }
      
      &.is-inner-page-featured {
        .news-card {
          &__content {
            position: absolute;
          }
        }
      }
    }

    // &[column="3"] {
    //   .news-card {
    //     &__content {
    //       position: relative;

    //       // &-wrapper {
    //       // @include media(
    //       //   (
    //       //     padding-bottom: (
    //       //       all: 0% !important
    //       //     )
    //       //   )
    //       // );
    //       // }
    //     }
    //   }
    // }

    .grid {
      @include media(
        (
          margin-top: (
            mobile: 100px,
          ),
          width: (
            all: calc(4 * #{$newsCell}),
            mobile: calc(2 * #{$newsCell}),
            // mobile: calc((100vw - 40px - 20px) / 2),
          ),
          height: (
            all: calc(3 * #{$newsCell}),
            mobile: auto,
          ),
          // flex: (
          //   mobile: 1,
          // ),
        )
      );
    }
  }
}

body {
  .slick-slider {
    .news-card {
      &[column="1"] {
        width: 100%;
      }
      &[column="2"] {
        width: 100%;
      }
      &[column="3"] {
        width: 100%;
      }
    }
  }

  .news-card {
    position: relative;

    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;

    .title-container {
      display: inline-flex;
      flex-direction: column;

      @include media(
        (
          min-height: (
            all: initial,
            mobile: 100px,
          ),
        )
      );

      .title {
        display: inline;

        transition: $transition;
        word-break: break-word;

        .underline {
          display: inline;

          transition: $transition;

          border-bottom: 1px solid;
          border-bottom-color: transparent;
        }

        & > div {
          display: inline-flex;
        }
      }

      .button {
        box-sizing: border-box;
        width: 240px;
        height: 70px;
        margin-top: 20px;

        color: $black;
        background: $white;
      }
    }

    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 100%;
      height: 100%;

      .title-container {
        .indicators {
          margin-top: 10px;
        }
      }
    }

    &:not(.post):not(.common-post):not(.news-card_list) {
      .additional {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &[column="3"] {
        width: 100%;

        .additional {
          position: relative;
        }
      }
    }

    .additional {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      width: 100%;

      .indicators {
        display: flex;
        align-items: flex-end;

        width: 100%;
      }

      .share {
        margin-bottom: 75px;
      }
    }

    .event {
      display: flex;
      justify-content: space-between;

      &:nth-child(2) {
        margin-left: calc(4 * #{$newsPadding});
      }
    }

    .grid {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      flex-shrink: 0;

      margin-left: $newsPadding;

      &.no-image {
        justify-content: flex-end;
      }

      &[side="height"] {
        align-items: flex-start;
        flex-direction: row-reverse;

        .triggers {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }

      .triggers {
        display: flex;
        justify-content: flex-end;

        &.replace {
          flex-direction: column-reverse;
        }

        .svg-symbol {
          svg {
            width: $newsCell;
            height: $newsCell;
          }
        }
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        width: $newsCell;
        height: $newsCell;

        @for $i from 1 through 10 {
          &[data-width="#{$i}"] {
            @include media(
              (
                width: (
                  all: calc(#{$i} * #{$newsCell}),
                  // mobile: 100%,
                  mobile: calc(2 * #{$newsCell}),
                ),
              )
            );
          }

          &[data-height="#{$i}"] {
            @include media(
              (
                height: (
                  all: calc(#{$i} * #{$newsCell}),
                  // mobile: 100%,
                  mobile: calc(2 * #{$newsCell}),
                ),
              )
            );
          }
        }
      }
    }

    &:before {
      position: absolute;
      z-index: -10;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      content: "";
      transition: $transition;

      opacity: 0;
      background: $black;

      &:not(.post):not(.common-post) {
        z-index: 10;
      }
    }

    &:hover {
      &:not(.post):not(.common-post) {
        .title {
          z-index: 25;
          .underline {
            border-color: inherit;
          }
        }

        &:not(.news-page) {
          @media (min-width: map-get($breakpoints, mobile) + 'px') {
            &:before {
              z-index: 20;

              cursor: pointer;

              opacity: $overlayOpacity;
            }
          }
        }
      }
    }

    &.common-post {
      width: 100%;
      padding: 0px;

      .news-card {
        &__content {
          position: relative;

          @include media(
            (
              padding: (
                all: calc(2 * #{$newsPadding}),
                mobile: $newsPadding,
              ),
              flex-direction: (
                mobile: column,
              ),
              height: (
                all: calc(var(--vh, 1vh) * 100 - #{$playerHeight}),
                tablet: initial,
              ),
            )
          );

          &-wrapper {
            padding: 0;
          }
        }
      }

      &[column="3"] {
        .information {
          height: auto;

          @include media(
            (
              margin-right: (
                all: 40px,
                mobile: 20px,
              ),
              height: (
                mobile: initial,
              ),
              flex: (
                mobile: initial,
              ),
              margin-bottom: (
                mobile: 40px,
              ),
            )
          );

          .title-container {
            display: flex;
            justify-content: center;

            width: 100%;
            height: 100%;

            .title {
              @include media(
                (
                  max-width: (
                    all: 800px,
                    desktop: 600px,
                  ),
                )
              );
            }
          }
        }

        .grid {
          position: relative;

          align-items: center;
          justify-content: center;

          margin-top: 0;
          margin-left: 0;

          @include media(
            (
              width: (
                all: calc(3 * #{$newsCell}),
                mobile: 100%,
              ),
              height: (
                all: 100%,
                tablet: 460px,
                mobile: 0,
              ),
              padding-top: (
                mobile: 100%,
              ),
            )
          );
        }

        .image {
          top: 0;
          left: 0;

          display: flex;

          width: 100%;
          max-width: 720px;
          max-height: 720px;
          margin: 0 auto;

          @include media(
            (
              height: (
                mobile: 100%,
              ),
              position: (
                mobile: absolute,
              ),
            )
          );
        }
      }
    }

    &.news-card_list {
      width: 100%;

      &:not([column="3"]) {
        .news-card {
          &__content {
            &-wrapper {
              padding: 0;
            }
          }
        }
      }

      &[column="3"] {
        .news-card {
          &__content {
            @include media(
              (
                position: (
                  all: absolute,
                  mobile: absolute
                ),
              )
            );

            &-wrapper {
              @include media(
                (
                  padding-bottom: (
                    all: $newsWithBackgroundImageProportion,
                    mobile: $newsWithBackgroundImageProportionMobile,
                  ),
                )
              );
            }
          }
        }
      }

      .news-card {
        &__content {
          position: relative;

          @include media(
            (
              padding: (
                all: calc(2 * #{$newsPadding}),
                mobile: $newsPadding,
              ),
            )
          );
        }
      }

      .information {
        height: auto;
      }

      .grid {
        @include media(
          (
            width: (
              all: calc(2 * #{$newsNextCell}),
              mobile: calc((100vw - 40px - 20px) / 2),
            ),
            height: (
              all: calc(2 * #{$newsNextCell}),
              mobile: calc((100vw - 40px - 20px) / 2),
            ),
          )
        );

        .image {
          width: 100%;
          height: 100%;
        }
      }

      &[column="1"] {
        height: auto;

        .additional {
          @include media(
            (
              margin-top: (
                all: 0,
                tablet: 100px,
              ),
            )
          );
        }
      }

      &[column="3"] {
        .grid {
          width: calc(2 * #{$newsNextCell});

          @include media(
            (
              height: (
                all: 460px,
                tablet: 420px,
                mobile: calc((100vw - 40px - 20px) / 2),
              ),
            )
          );
        }
      }
    }

    &.post {
      flex-direction: row;

      width: 100%;
      width: 100%;

      .news-card {
        &__content {
          position: relative;

          @include media(
            (
              padding: (
                all: 40px,
                mobile: 20px,
              ),
              align-items: (
                all: flex-end,
                mobile: initial,
              ),
              height: (
                all: calc(3 * #{$newsCell}),
                tablet: 460px,
                mobile: 150vw,
              ),
            )
          );

          &-wrapper {
            padding: 0;
          }
        }
      }

      .preset {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        opacity: 0.3;
        background: $black;
      }

      .information {
        position: relative;
        z-index: 5;

        align-items: flex-start;
        justify-content: space-between;

        width: 100%;
        height: auto;

        @include media(
          (
            flex-direction: (
              all: row-reverse,
              mobile: column,
            ),
          )
        );

        .additional {
          display: flex;

          @include media(
            (
              min-width: (
                tablet: 200px,
                mobile: initial,
              ),
              width: (
                all: 100%,
                tablet: 200px,
                mobile: 100%,
              ),
              flex: (
                all: 1,
                tablet: initial,
                mobile: 1,
              ),
            )
          );

          .event {
            width: 100%;
            padding-top: $newsPadding;

            border-top: 1px solid $white;
          }
        }

        .title-container {
          @include media(
            (
              width: (
                all: 66.67%,
                tablet: 100%,
                mobile: 100%,
              ),
              margin-left: (
                all: 120px,
                tablet: 40px,
                mobile: 0,
              ),
            )
          );

          & > div {
            padding-right: 22%;
          }
        }
      }

      .grid {
        display: none;
      }
    }
  }
}

.news-card.featured {
  .news-card {
    &__content {
      &-wrapper {
        @include media(
          (
            height: 0,
            padding-bottom: (
              all: $newsWithBackgroundImageProportion,
              mobile: $newsWithBackgroundImageProportionMobile,
            ),
          )
        );
      }
    }
  }
}
