@import '../../css/guideline.scss';

body {
  .section-link-container {
    color: $black;
    user-select: none;
    background: $white;

    @include media(
      (
        padding-top: (
          all: 80px,
          mobile: 60px
        ),
        padding-bottom: (
          all: 80px,
          mobile: 60px
        )
      )
    );

    &.full {
      padding: 0;

      .section-link {
        margin: 0;
        width: 100%;
        justify-content: space-between;
      }

      .wrapper {
        width: 100%;
        padding: 0;
      }
    }

    &.no-padding {
      margin: 0;
      padding: 0;

      .wrapper {
        padding: 0;
      }
    }

    .section-link {
      position: relative;
      flex: 1 1 auto;
      color: $black;
      display: flex;
      cursor: pointer;
      align-items: center;
      transition: $transition;
      text-transform: uppercase;

      & > * {
        @include media(
          (
            margin: (
              0 10px
            )
          )
        );

        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }

      @include media(
        (
          width: (
            mobile: 100%
          ),
          justify-content: (
            all: flex-start,
            mobile: space-between
          )
        )
      );
    }

    .title-container {
      align-items: center;
      display: flex;

      @include media(
        (
          min-width: (
            all: 300px,
            mobile: 0
          ),
          height: (
            all: 40px,
            mobile: 20px
          )
        )
      );

      .title {
        overflow: hidden;
        transition: $transition;

        @include media(
          (
            padding-top: (
              all: 8px,
              mobile: 6px
            ),
            font-weight: (
              all: bold,
              mobile: 500
            )
          )
        );
      }

      .share {
        position: absolute;
        top: 50%;
        width: 0;
        margin-right: 0;
        overflow: hidden;
        transform: translateY(-50%);
        transition: $transition;
      }
    }

    .share-button-container {
      overflow: hidden;
      height: 60px;
      width: 60px;
      display: flex;
      background: transparent;
      align-items: center;
      box-sizing: border-box;
      border: 2px solid $black;
      border-color: transparent !important;
      z-index: 15;

      .share-button {
        width: 100%;
        display: flex;
        align-items: center;

        transition: $transition;

        .svg-image {
          width: 100%;
          margin-left: 0;
          min-width: 100%;

          & > div {
            align-items: center;
            display: flex;
            margin: auto;
            height: 40px;
            width: 40px;
          }

          svg {
            width: 10%;
            height: 10%;
            stroke: $black;

            path {
              fill: transparent;
              stroke: $black;
            }
          }
        }
      }
    }

    .icon {
      transition: $transition;

      @include media(
        (
          width: (
            all: 40px
          ),
          min-width: (
            all: 40px
          ),
          height: (
            all: 40px
          )
        )
      );

      & > div {
        width: 100%;
        height: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
        fill: $black;

        path {
          fill: $black;
          stroke: $black;
          transition: $transition;
        }
      }
    }
  }

  .section-link-container {
    &:not(.gray):not(.red):not(.white) {
      .section-link {
        color: $white;

        .icon {
          svg {
            fill: $white;

            path {
              fill: $white;
              stroke: $white;
            }
          }
        }

        .share-button-container {
          border-color: $white;

          svg {
            stroke: $white;

            path {
              fill: transparent;
              stroke: $white;
            }
          }
        }

        &:hover {
          color: $gray;

          .icon {
            svg {
              fill: $gray;
            }
          }

          .share-button-container {
            svg {
              stroke: $gray;

              path {
                fill: transparent;
                stroke: $gray;
              }
            }
          }
        }
      }
    }

    &.gray {
      background: $gray;
    }

    &.black {
      background: $black;
    }

    &.dark {
      background: $lightBlack;
    }
  }

  .section-link-container {
    &:not(.shared) {
      .share-button-container {
        .share-button {
          .share:first-child {
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    &.shared {
      .share-button-container {
        .share-button {
          transform: translateX(-100%);

          .share:last-child {
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .title-container {
        .title {
          font-size: 0;
          overflow: hidden;
        }

        .share {
          $count: 3;
          width: calc(40px * #{$count} + (#{$count} - 1) * 20px);

          @include media(
            (
              margin-right: (
                all: 60px,
                mobile: 0
              )
            )
          );
        }
      }
    }
  }
}
