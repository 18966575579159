@import '../../css/guideline.scss';

body {
  .section-title {
    display: flex;
    flex: 1 1 auto;
    box-sizing: border-box;
    justify-content: space-between;

    & > .wrapper {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;

      @include media(
        (
          flex-direction: (
            all: row,
            tablet: column
          ),
          align-items: (
            all: center,
            tablet: flex-start
          )
        )
      );
    }

    @include media(
      (
        padding: (
          all: 80px 0,
          tablet: 60px 0
        )
      )
    );

    &.title-start {
      @include media(
        (
          align-items: (
            all: flex-start,
            tablet: initial
          )
        )
      );

      & > .wrapper {
        @include media(
          (
            align-items: (
              all: flex-start,
              tablet: initial
            )
          )
        );
      }

      .title {
        @include media(
          (
            align-items: (
              all: flex-start,
              tablet: initial
            )
          )
        );
      }
    }

    &.header {
      box-sizing: border-box;
      // min-height: 300px;
      padding-bottom: 60px;
      padding-top: 60px;

      & > .wrapper {
        & > :first-child {
          @include media(
            (
              margin-bottom: (
                all: 0,
                tablet: 60px
              )
            )
          );
        }
      }

      .title-container {
        color: $white;
        transition: $transition;

        .title {
          display: inline;
        }

        .svg-symbol,
        span {
          transition: $transition;
          opacity: 0.5;
        }

        &:hover {
          transform: translateX(-2px);

          span,
          .svg-symbol {
            opacity: 1;
          }
        }

        span {
          letter-spacing: 0.5px;
          color: $white;
        }

        @include media(
          (
            margin-bottom: (
              all: 0,
              mobile: 40px
            )
          )
        );
      }

      .tabs-container {
        .wrapper {
          @include media(
            (
              padding: (
                all: 0,
                mobile: 0 0px 0 60px
              )
            )
          );
        }
      }

      @include media(
        (
          padding-top: (
            mobile: 20px
          ),
          padding-bottom: (
            mobile: 20px
          )
        )
      );

      &.content-height {
        height: auto;
      }
    }

    &.no-padding {
      padding: 0;

      .wrapper {
        padding: 0;
      }
    }

    .title-container {
      align-items: center;
      display: flex;
      height: 40px;

      &.linked {
        cursor: pointer;
      }

      .title {
        display: flex;
        padding-top: 8px;
        align-items: center;

        @include media(
          (
            font-weight: (
              all: bold,
              mobile: 500
            ),
            padding-top: (
              all: 8px,
              mobile: 6px
            )
          )
        );
      }

      .svg-symbol {
        margin-right: 20px;

        svg {
          height: 40px;
          width: 40px;
        }
      }
    }

    .arrows {
      display: flex;

      @include media(
        (
          margin-top: (
            tablet: 40px,
            mobile: 20px
          )
        )
      );

      .arrow {
        cursor: pointer;
        width: 40px;
        height: 40px;

        &.right {
          margin-left: 100px;
          transform: scaleX(-1);

          @include media(
            (
              margin-left: (
                mobile: 40px
              )
            )
          );
        }
      }
    }
  }

  .section-title {
    color: $black;

    &.gray {
      background: $gray;
    }

    &.black {
      background: $black;
    }

    &.dark {
      background: $lightBlack;
    }

    &.blue {
      background: $blue;
    }

    &.red {
      background: $red;
    }

    .title {
      .svg-symbol {
        svg {
          fill: $black;
        }
      }
    }

    .arrows {
      .arrow {
        svg {
          fill: $black;
          path {
            fill: $black;
            stroke: $black;
          }
        }
      }
    }

    &:not(.gray):not(.white) {
      color: $white;

      .title {
        .svg-symbol {
          svg {
            fill: $white;
          }
        }
      }

      .arrows {
        .arrow {
          svg {
            fill: $white;
            path {
              fill: $white;
              stroke: $white;
            }
          }
        }
      }
    }
  }
}
