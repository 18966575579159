@import '../../css/guideline.scss';

body {
  .delete-acc {
    position: fixed;
    z-index: 9999;
    width: 720px;
    background: #EDE9E6;
    color: black;
    padding: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .cross {
      position: absolute;
      right: 50px;
      top: 50px;
    }
    .title {
      margin-bottom: 32px;
      font-size: 40px;
      // font-weight: 700;
    }
    .desc {
      margin-bottom: 32px;
      font-size: 24px;
      // font-weight: 700;
    }
    .buttons {
      text-align: center;
    }
    .button {
      font-size: 24px;
      font-weight: 700;
      margin: 0 32px;
      &.black {
        background: black;
        color: white;
      }
      &.white {
        border: 1px solid black;
      }
    }
  }
  @media screen and (max-width: 720px) {
    .delete-acc {
      width: 90%;
      padding: 20px;
      .buttons {
        position: relative;
      }
      .cross {
        width: 24px;
        top: 10px;
        right: 20px;
      }
      .button {
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        margin-bottom: 16px;
        font-size: 18px;
      }
      .title {
        font-size: 24px;
        width: calc(100% - 24px);
        box-sizing: border-box;
      }
      .desc {
        font-size: 20px;
      }
    }
  }
}