@import '../../css/guideline.scss';

body {
  .hamburger {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &:hover {
      line {
        background-color: $white;

        &:first-child {
          transform: translateY(-1px);
        }
        &:last-child {
          transform: translateY(1px);
        }
      }
    }

    .svg-image {
      width: 40px;
      height: 40px;

      line {
        transition: $transition;
      }
    }

    &.player {
      width: 100px;
      height: 100px;
      cursor: pointer;
      background: transparent;
      transition: $transition;
      border-left: $lightBorder;

      &:hover {
        background: $lightBlack;

        .hamburger-content {
          .line {
            background-color: $white;
          }
        }
      }
    }

    .hamburger-content {
      display: flex;
      cursor: pointer;
      position: relative;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      transition: $transition;

      &.player {
        width: 100px;
        height: 100px;
        background: transparent;

        .line {
          background-color: $darkGray;
        }
      }
    }
  }
}
