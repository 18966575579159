@import '../../css/guideline.scss';

body {
  .logotype-component {
    position: relative;

    display: flex;
    user-select: none;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-width: 30px;
    max-width: 120px;
    height: 30px;

    & > div {
      display: table;

      width: 100%;
      height: 100%;
    }

    svg {
      width: 100%;

      opacity: 0.9;

      fill: $white;

      &:hover {
        opacity: 1;
      }
    }
  }
}
