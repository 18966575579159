@import '../../css/guideline.scss';

$liveSize: 430px;
$controlSize: 60px;
$controlColor: $white;
$modePadding: 6px 20px;
$modePlayerPadding: 1px 3px;

body {
  .live-control {
    display: flex;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    background: $black;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .loader,
    .loader svg {
      width: 30px;
      height: 30px;
    }

    .little-live-visible-block {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);

      .svg-image {
        display: none;
        path {
          stroke: $white;
        }
      }

      &.visible {
        opacity: 1;
        .svg-image {
          display: block;
        }
      }
    }

    svg {
      width: 40px;
      height: 40px;
    }
  }
}
