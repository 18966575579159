@import '../../css//guideline.scss';

$SLIDER_PADDING: 30px;

body {
  .slider {
    position: relative;
    position: relative;
    z-index: $zIndexSlider;

    width: 100%;

    background: $black;

    @include media(
      (
        padding-bottom: (
          all: $newsWithBackgroundImageProportion,
          mobile: $newsWithBackgroundImageProportionMobile
        )
      )
    );

    .slick-ssr {
      .slick-slide {
        width: 100vw;
        min-width: 100vw;
      }
    }

    .carousel-arrows {
      position: absolute;
      z-index: 1;
      top: $playerHeight;

      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;

      width: 40px;

      @include media(
        (
          top: (
            all: $playerHeight,
            tablet: 20px
          ),
          right: (
            all: 40px,
            mobile: 20px
          )
        )
      );

      .current-slide {
        margin-bottom: 20px;

        text-align: center;

        color: $white;
      }

      .svg-image {
        path {
          fill: $white;
        }

        &.right {
          margin-bottom: 20px;

          transform: scaleX(-1);
        }
      }
    }

    .slick-slider {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      .slick-slide {
        & > div {
          width: 100%;
        }
      }

      .slick-list,
      .slick-track {
        height: 100%;
      }
    }
  }
}