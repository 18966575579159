@import '../../css/guideline.scss';

.error-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  box-sizing: border-box;
  height: calc(100vh - #{$playerHeight});

  color: $white;
  background-color: $black;

  @include media(
    (
      flex-direction: (
        all: row,
        mobile: column-reverse,
      ),
      height: (
        all: calc(var(--vh, 1vh) * 100 - #{$playerHeight}),
        mobile: auto,
      ),
      padding: (
        all: 0 160px,
        tablet: 0 40px,
        mobile: 20px 20px 40px,
      ),
    )
  );

  .column {
    display: flex;
    flex-direction: column;

    @include media(
      (
        align-items: (
          mobile: center,
        ),
      )
    );

    + .column {
      @include media(
        (
          margin-left: (
            all: 20px,
            mobile: 0,
          ),
          margin-bottom: (
            all: 0,
            mobile: 20px,
          ),
        )
      );
    }
  }

  .error-num {
    letter-spacing: -3px !important;
    text-transform: uppercase !important;

    font-size: 144px !important;
    line-height: 134px !important;
  }

  .error-text {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .link-container {
    display: flex;
    .link {
      max-width: 250px;
      padding: 20px 60px;

      text-align: center;

      color: $white;
      border: 2px solid $white;
    }
  }

  .image-container {
    width: 100%;
    min-width: 400px;
    margin: 0 auto;

    @include media(
      (
        min-width: (
          all: 400px,
          tablet: 360px,
          mobile: auto,
        ),
      )
    );

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }
}
